import { gql, useQuery } from "@apollo/client";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import useDateFilter from "../../../hooks/useDateFilter";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import formatMoney from "../../../utils/formatMoney";
import iterateObjectValuesNullToBlank from "../../../utils/iterateObjectValuesNullToBlank";
import DataTable from "../../common/DataTable";
import DateFilter from "../../common/DateFilter";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";

export const SALES_REPORT_ITEMS = gql`
  query SalesReportItems(
    $startDate: DateTime
    $endDate: DateTime
    $branchId: String
  ) {
    salesReportItems(
      startDate: $startDate
      endDate: $endDate
      branchId: $branchId
    ) {
      date
      dineIn
      takeOut
      total
      numberOfPax
      vatableTransaction
      scVatExempt
      pwdVatExempt
      sc
      pwd
    }
  }
`;

const SalesReportPage = () => {
  const title = `Sales Report`;
  const location = useLocation();
  const csvLink = useRef(null);
  const [exportData, setExportData] = useState([]);
  const { branchFilterQueryString } = useBranchContext();

  const {
    handleChangeStartDate,
    handleChangeEndDate,
    handleClearDateFilters,
    startDate,
    endDate,
    startDateQueryString,
    endDateQueryString,
  } = useDateFilter();

  const variables = {
    ...(branchFilterQueryString && {
      branchId: branchFilterQueryString,
    }),
    ...(startDate && {
      startDate: new Date(startDateQueryString),
    }),
    ...(endDate && { endDate: new Date(endDateQueryString) }),
  };
  const skipCriteria = !branchFilterQueryString || !(startDate && endDate);
  const {
    data: { salesReportItems } = {},
    loading,
    refetch,
  } = useQuery(SALES_REPORT_ITEMS, {
    skip: skipCriteria,
    fetchPolicy: "network-only",
    variables,
  });

  const handleDownload = async () => {
    const { data: { salesReportItems } = {} } = await refetch({
      skip: null,
      take: null,
    });

    setExportData(
      salesReportItems.map((salesReportItem) => {
        return {
          ...iterateObjectValuesNullToBlank({
            ...salesReportItem,
          }),
        };
      })
    );
    csvLink.current.link.click();
  };

  const columns = [
    {
      header: "Date",
      accessor: "date",
      Cell: (children) => children && format(new Date(children), "dd MMM yyyy"),
    },
    {
      header: "Dine In",
      accessor: "dineIn",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Take Out",
      accessor: "takeOut",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Total",
      accessor: "total",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "No. of Pax",
      accessor: "numberOfPax",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Vatable Transaction",
      accessor: "vatableTransaction",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "SC VE",
      accessor: "scVatExempt",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "PWD VE",
      accessor: "pwdVatExempt",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "SC",
      accessor: "sc",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "PWD",
      accessor: "pwd",
      Cell: (children) => children && formatMoney(children),
    },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <CSVLink
        component="button"
        headers={columns
          .filter(({ accessor }) => accessor !== "id")
          .map((column) => {
            return { label: column.header, key: column.accessor };
          })}
        filename={`${location.pathname}-${format(
          Date.now(),
          "yyyy-MM-dd-HH:mm:ss"
        )}.csv`}
        data={exportData}
        ref={csvLink}
      />
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Button
          disabled={skipCriteria}
          onClick={handleDownload}
          variant="contained"
          startIcon={<DownloadIcon />}
          sx={{ marginLeft: "auto" }}
        >
          Download
        </Button>

        <Typography variant="h3" color="primary" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
        >
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleClearDateFilters={handleClearDateFilters}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={salesReportItems} columns={columns} />
        </Paper>
      </AdminListContainer>
    </>
  );
};

export default SalesReportPage;
