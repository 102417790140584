import { useQuery } from "@apollo/client";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Autocomplete,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Link,
  Stack,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import { useBranchContext } from "../../contexts/branchContext";
import usePopper from "../../hooks/usePopper";
import logoDarkBg from "../../images/logos/logo-dark-bg.png";
import { BRANCHES } from "../admin/branches/BranchesPage";
import SignoutButton from "../authentication/SignoutButton";
import Popper from "../common/Popper";

const TopNav = ({ toggleDrawer }) => {
  let location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const { branchFilterQueryString, handleSubmitBranchFilter } =
    useBranchContext();

  const { data: { branches } = {}, loading } = useQuery(BRANCHES, {
    fetchPolicy: "network-only",
  });

  const { handleClick, setOpen, open, anchorEl, placement } = usePopper();

  if (loading) return null;

  return (
    <>
      <AppBar position="fixed" sx={{ displayPrint: "none" }}>
        <Toolbar>
          {currentUser && (
            <IconButton
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              edge="start"
              color="inherit"
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          )}

          <Link
            component="button"
            onClick={() => {
              navigate("/");
            }}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Box
              component="img"
              src={logoDarkBg}
              alt="logo"
              sx={{
                height: "40px",
                marginLeft: (theme) => theme.spacing(1),
              }}
            />
          </Link>

          <Stack
            direction="row"
            alignItems="center"
            sx={{
              marginLeft: "auto",
            }}
          >
            {currentUser ? (
              <>
                {location.pathname !== "/tip-calculator" && (
                  <Autocomplete
                    disablePortal
                    id="branch"
                    name="branch"
                    sx={{ minWidth: "250px" }}
                    options={branches}
                    getOptionLabel={(option) => option.name}
                    value={
                      branches?.find(
                        ({ id }) => branchFilterQueryString === id
                      ) ?? null
                    }
                    onChange={(event, newValue) =>
                      handleSubmitBranchFilter("direct", newValue?.id)(event)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.common.white,
                          borderRadius: "4px",
                        }}
                        size="small"
                        placeholder="Branch"
                      />
                    )}
                  />
                )}
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <div>
                    <IconButton
                      aria-label="user avatar"
                      color="inherit"
                      onClick={handleClick("bottom-end")}
                      sx={{ p: 0, ml: 2 }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: (theme) => theme.palette.secondary.main,
                          color: (theme) => theme.palette.primary.main,
                          fontWeight: "bold",
                        }}
                      >
                        {currentUser?.username.charAt(0).toUpperCase()}
                      </Avatar>
                    </IconButton>
                    <Popper
                      open={open}
                      anchorEl={anchorEl}
                      placement={placement}
                    >
                      <Stack alignItems="center" sx={{ mt: 2 }}>
                        <AccountCircleIcon fontSize="large" color="primary" />
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          gutterBottom
                        >
                          {currentUser?.username}
                        </Typography>
                        <SignoutButton
                          variant="contained"
                          callback={() => {
                            navigate("/signin");
                            setOpen(false);
                          }}
                        />
                      </Stack>
                    </Popper>
                  </div>
                </ClickAwayListener>
              </>
            ) : (
              <Button
                color="inherit"
                sx={{ ml: "auto" }}
                size="large"
                onClick={() => navigate("/signin")}
              >
                Sign in
              </Button>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopNav;
