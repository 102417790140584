import { Navigate, Route, Routes as ReactRouterRoutes } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import AttendancesPage from "../admin/attendance/AttendancesPage";
import BillingsPage from "../admin/billings/BillingsPage";
import BranchesPage from "../admin/branches/BranchesPage";
import DiscountCardsPage from "../admin/discount_cards/DiscountCardsPage";
import EmployeesPage from "../admin/employees/EmployeesPage";
import ViewEmployeePage from "../admin/employees/ViewEmployeePage";
import IngredientsPage from "../admin/ingredients/IngredientsPage";
import InventoriesPage from "../admin/inventories/InventoriesPage";
import ItemsPage from "../admin/items/ItemsPage";
import ViewItemPage from "../admin/items/ViewItemPage";
import ItemCategoriesPage from "../admin/item_categories/ItemCategoriesPage";
import MenuItemsPage from "../admin/menu_items/MenuItemsPage";
import PendingBillsPage from "../admin/pending_bills/PendingBillsPage";
import ProductsPage from "../admin/products/ProductsPage";
import ProductCategoriesPage from "../admin/product_categories/ProductCategoriesPage";
import PurchaseOrdersPage from "../admin/purchase_orders/PurchaseOrdersPage";
import ErrorlogsPage from "../admin/reports/ErrorlogsPage";
import InventoryReportPage from "../admin/reports/InventoryReportPage";
import SalesReportPage from "../admin/reports/SalesReportPage";
import TipCalculatorPage from "../admin/reports/TipCalculatorPage";
import SuppliersPage from "../admin/suppliers/SuppliersPage";
import TransferSlipsPage from "../admin/transfer_slips/TransferSlipsPage";
import UsersPage from "../admin/users/UsersPage";
import WastagesPage from "../admin/wastages/WastagesPage";
import SigninPage from "../authentication/SigninPage";
import HomePage from "../HomePage";
import OrderItemsPage from "../OrderItemsPage";
import ProtectedRoute from "./ProtectedRoute";

const Routes = () => {
  const { currentUser } = useAuthContext();
  return (
    <ReactRouterRoutes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/signin" element={<SigninPage />} />
      <Route element={<ProtectedRoute user={currentUser} />}>
        <Route exact path="/branches" element={<BranchesPage />} />
        <Route exact path="/users" element={<UsersPage />} />
        <Route
          exact
          path="/product-categories"
          element={<ProductCategoriesPage />}
        />
        <Route exact path="/products" element={<ProductsPage />} />
        <Route exact path="/menu-items" element={<MenuItemsPage />} />
        <Route exact path="/employees" element={<EmployeesPage />} />
        <Route exact path="/employees/:id" element={<ViewEmployeePage />} />
        <Route exact path="/pending-bills" element={<PendingBillsPage />} />
        <Route exact path="/billings" element={<BillingsPage />} />
        <Route exact path="/discount-cards" element={<DiscountCardsPage />} />
        <Route path="/order-items" element={<OrderItemsPage />} />
        <Route path="/attendances" element={<AttendancesPage />} />
        <Route exact path="/item-categories" element={<ItemCategoriesPage />} />
        <Route exact path="/items" element={<ItemsPage />} />
        <Route exact path="/items/:id" element={<ViewItemPage />} />
        <Route exact path="/suppliers" element={<SuppliersPage />} />
        <Route exact path="/purchase-orders" element={<PurchaseOrdersPage />} />
        <Route exact path="/transfer-slips" element={<TransferSlipsPage />} />
        <Route exact path="/wastages" element={<WastagesPage />} />
        <Route exact path="/inventories" element={<InventoriesPage />} />
        <Route exact path="/ingredients" element={<IngredientsPage />} />
        <Route path="/inventory-report" element={<InventoryReportPage />} />
        <Route path="/tip-calculator" element={<TipCalculatorPage />} />
        <Route path="/sales-report" element={<SalesReportPage />} />
        <Route path="/error-logs" element={<ErrorlogsPage />} />
      </Route>
    </ReactRouterRoutes>
  );
};

export default Routes;
