import { useState } from "react";
import queryString from "query-string";
import { format, isDate } from "date-fns";
import { useNavigate, useLocation } from "react-router";

const useDateFilter = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const paramsObj = queryString.parse(location.search);
  const startDateQueryString = paramsObj.startDate;
  const endDateQueryString = paramsObj.endDate;
  const [startDate, setStartDate] = useState(startDateQueryString);
  const dateQueryString = paramsObj.date || "";
  const [endDate, setEndDate] = useState(endDateQueryString);

  const [date, setDate] = useState(dateQueryString);
  const handleChangeStartDate = (value) => {
    const formatValue = format(value, "yyyy-MM-dd");
    setStartDate(formatValue);
    if (endDate) {
      delete paramsObj["page"];
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({
          ...paramsObj,
          startDate: formatValue,
          endDate: endDate,
        }),
      });
    }
  };

  const handleChangeEndDate = (value) => {
    if (isDate(value)) {
      const formatValue = format(value, "yyyy-MM-dd");
      setEndDate(formatValue);
      if (startDate) {
        delete paramsObj["page"];
        navigate({
          pathname: location.pathname,
          search: queryString.stringify({
            ...paramsObj,
            startDate: startDate,
            endDate: formatValue,
          }),
        });
      }
    }
  };

  const handleChangeDate = (value) => {
    const formatValue = format(value, "yyyy-MM-dd");
    setDate(formatValue);
    delete paramsObj["page"];
    navigate({
      pathname: location.pathname,
      search: queryString.stringify({
        ...paramsObj,
        date: formatValue,
      }),
    });
  };

  const handleClearDateFilters = () => {
    delete paramsObj["startDate"];
    delete paramsObj["endDate"];
    delete paramsObj["date"];
    navigate({
      pathname: location.pathname,
      search: queryString.stringify({ ...paramsObj }),
    });
    setStartDate("");
    setEndDate("");
    setDate("");
  };

  return {
    handleChangeStartDate,
    handleChangeEndDate,
    handleClearDateFilters,
    startDate,
    endDate,
    startDateQueryString,
    endDateQueryString,
    dateQueryString,
    date,
    handleChangeDate,
  };
};

export default useDateFilter;
