// iterate through object and change null values to empty object

const iterateObjectValuesNullToBlank = (obj) => {
  return Object.keys(obj).reduce((acc, cur) => {
    if (Array.isArray(obj[cur])) {
      return {
        ...acc,
        [cur]: obj[cur].map((item) => iterateObjectValuesNullToBlank(item)),
      };
    }
    if (typeof obj[cur] === "object") {
      // double check bec typeof null === obj (weird thing about javascript)
      if (obj[cur] === null) {
        return { ...acc, [cur]: "" };
      } else {
        return { ...acc, [cur]: iterateObjectValuesNullToBlank(obj[cur]) };
      }
    }
    return { ...acc, [cur]: obj[cur] };
  }, {});
};

export default iterateObjectValuesNullToBlank;
