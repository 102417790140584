import React from "react";

const CombineProviders = (props) => {
  const { providers = [], children } = props;
  return (
    <>
      {providers.reduceRight((acc, Provider) => {
        return <Provider>{acc}</Provider>;
      }, children)}
    </>
  );
};

export default CombineProviders;
