import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Page from "./components/page/Page";
import apolloClient from "./libs/apolloClient";
import theme from "./styles/theme";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <HelmetProvider>
            <Router>
              <CssBaseline />
              <Page />
            </Router>
          </HelmetProvider>
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
