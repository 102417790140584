import { Box } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthProvider } from "../../contexts/authContext";
import { BranchProvider } from "../../contexts/branchContext";
import { DialogProvider } from "../../contexts/dialogContext";
import { SnackbarProvider } from "../../contexts/snackbarContext";
import DrawerHeader from "../../styles/components/DrawerHeader";
import CombineProviders from "../common/CombineProviders";
import ErrorBoundary from "../common/ErrorBoundary";
import Meta from "../common/Meta";
import Dialog from "./Dialog";
import Drawer from "./Drawer";
import Routes from "./Routes";
import Snackbar from "./Snackbar";
import TopNav from "./TopNav";

const Page = () => {
  let location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (drawerOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(drawerOpen);
  };

  return (
    <>
      <Meta title="Meatsumo" />
      <CombineProviders
        providers={[
          AuthProvider,
          BranchProvider,
          SnackbarProvider,
          DialogProvider,
        ]}
      >
        {location.pathname !== "/signin" && (
          <TopNav toggleDrawer={toggleDrawer} />
        )}
        <Drawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
        <DrawerHeader />
        <Box
          component="main"
          sx={{
            maxWidth: (theme) => theme.breakpoints.values.lg,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            padding: 1,
          }}
        >
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Box>

        <Dialog />
        <Snackbar />
      </CombineProviders>
    </>
  );
};

export default Page;
