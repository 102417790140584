import { gql, useQuery } from "@apollo/client";
import React from "react";
import Loading from "../components/common/Loading";

const AuthContext = React.createContext();

AuthContext.displayName = "AuthContext";

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      username
    }
  }
`;

const AuthProvider = ({ children }) => {
  const { data: { currentUser } = {}, loading: currentUserLoading } = useQuery(
    CURRENT_USER,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );
  if (currentUserLoading) return <Loading />;

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentUserLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  return React.useContext(AuthContext);
};

export { AuthProvider, useAuthContext };
