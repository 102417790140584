import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import formatMoney from "../../../utils/formatMoney";
import NestedTable from "../../common/NestedTable";

const SalesSummary = ({
  salesSummaryPerProduct = [],
  salesSummaryTotal = [],
  salesSummaryPerModeOfPayment = [],
}) => {
  const salesSummaryPerProductColumns = [
    { header: "Product", accessor: "product" },
    {
      header: "Quantity",
      accessor: "quantity",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Subtotal",
      accessor: "subtotal",
      Cell: (children) => children && formatMoney(children),
    },
  ];

  const salesSummaryPerModeOfPaymentColumns = [
    { header: "Mode of Payment", accessor: "modeOfPayment" },

    {
      header: "Net Total",
      accessor: "netTotal",
      Cell: (children) => children && formatMoney(children),
    },
  ];

  return (
    <Paper sx={{ my: 2, p: 1, overflow: "auto" }}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        Sales Summary Per Product
      </Typography>
      <NestedTable
        columns={salesSummaryPerProductColumns}
        data={salesSummaryPerProduct}
      />
      <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>
        Sales Summary Per MOP
      </Typography>
      <NestedTable
        columns={salesSummaryPerModeOfPaymentColumns}
        data={salesSummaryPerModeOfPayment}
      />
      <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>
        Sales Summary Total
      </Typography>
      <Grid container>
        <Grid item xs={7}>
          <Typography align="right">Service Charge:</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography align="right">
            {formatMoney(
              salesSummaryTotal.reduce((acc, cur) => {
                if (!cur.serviceCharge) return acc;
                return acc + cur.serviceCharge;
              }, 0)
            )}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography align="right">Discount:</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography align="right">
            {formatMoney(
              salesSummaryTotal.reduce((acc, cur) => {
                if (!cur.discount) return acc;
                return acc + cur.discount;
              }, 0)
            )}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography align="right" sx={{ fontWeight: "bold" }}>
            Total Sales:
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography align="right" sx={{ fontWeight: "bold" }}>
            {formatMoney(
              salesSummaryTotal.reduce((acc, cur) => {
                if (!cur.totalSales) return acc;
                return acc + cur.totalSales;
              }, 0)
            )}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography align="right">Total Dine-in:</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography align="right">
            {formatMoney(
              salesSummaryTotal.reduce((acc, cur) => {
                if (!cur.totalSales || cur.productCategoryType === "TAKE_OUT")
                  return acc;
                return acc + cur.totalSales;
              }, 0)
            )}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography align="right">Total Takeout:</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography align="right">
            {formatMoney(
              salesSummaryTotal.reduce((acc, cur) => {
                if (!cur.totalSales || cur.productCategoryType === "DINE_IN")
                  return acc;
                return acc + cur.totalSales;
              }, 0)
            )}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SalesSummary;
