import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import React from "react";
import logoLightBg from "../images/logos/logo-light-bg.png";

const Container = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100vw",
  height: "100vh",
  left: "50%",
  right: "50%",
  top: "0",
  bottom: "0",
  marginLeft: "-50vw",
  marginRight: "-50vw",
}));

const HomePage = () => {
  return (
    <Container>
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          px: 2,
        }}
      >
        <Box
          component="img"
          src={logoLightBg}
          alt="logo"
          sx={{
            height: "80px",
            maxWidth: "100%",
            display: "block",
          }}
        />
        <Typography variant="h6" sx={{ letterSpacing: "5px" }}>
          Management System
        </Typography>
      </Box>
    </Container>
  );
};

export default HomePage;
