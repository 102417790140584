import { gql, useQuery } from "@apollo/client";
import { Paper, Stack, Typography } from "@mui/material";
import { endOfDay, format, startOfDay } from "date-fns";
import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import useDateFilter from "../../../hooks/useDateFilter";
import useQueryString from "../../../hooks/useQueryString";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import formatMoney from "../../../utils/formatMoney";
import DataTable from "../../common/DataTable";
import DataTableAccordion from "../../common/DataTableAccordion";
import DateFilter from "../../common/DateFilter";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

export const PURCHASE_ORDERS = gql`
  query PurchaseOrders(
    $skip: Int
    $take: Int
    $where: PurchaseOrderWhereInput
    $orderBy: [PurchaseOrderOrderByInput!]
  ) {
    purchaseOrders(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      date
      number
      branch {
        id
        name
      }
      supplier {
        id
        name
      }
      requestedBy {
        id
        username
      }
      total
      datePurchased
      reimbursedTo
      salesDate
      remarks
      purchaseOrderItems {
        id
        quantity
        item {
          id
          unit
          name
        }
        price
      }
    }
  }
`;

const PURCHASE_ORDERS_COUNT = gql`
  query PurchaseOrdersCount($where: PurchaseOrderWhereInput) {
    purchaseOrdersCount(where: $where)
  }
`;

const PurchaseOrdersPage = () => {
  const title = `Purchase Orders`;
  const { branchFilterQueryString } = useBranchContext();

  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;

  const {
    handleChangeStartDate,
    handleChangeEndDate,
    handleClearDateFilters,
    startDate,
    endDate,
    startDateQueryString,
    endDateQueryString,
  } = useDateFilter();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");

  const where = {
    ...(branchFilterQueryString && {
      branch: {
        id: {
          equals: branchFilterQueryString,
        },
      },
    }),
    ...(startDate &&
      endDate && {
        date: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
        },
      }),

    ...(searchQueryString && {
      OR: [
        {
          reimbursedTo: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
        {
          remarks: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
        {
          number: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
        {
          supplier: {
            name: {
              contains: searchQueryString,
              mode: "insensitive",
            },
          },
        },
        {
          requestedBy: {
            username: {
              contains: searchQueryString,
              mode: "insensitive",
            },
          },
        },
        {
          purchaseOrderItems: {
            some: {
              item: {
                name: {
                  contains: searchQueryString,
                  mode: "insensitive",
                },
              },
            },
          },
        },
      ],
    }),
  };
  const { data: { purchaseOrders } = {}, loading } = useQuery(PURCHASE_ORDERS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
      orderBy: [{ number: "desc" }],
    },
  });

  const columns = [
    {
      header: "Branch",
      accessor: "branch.name",
    },
    {
      header: "Date",
      accessor: "date",
      Cell: (children) => children && format(new Date(children), "dd MMM yyyy"),
    },
    {
      header: "PO No.",
      accessor: "number",
    },
    {
      header: "Supplier",
      accessor: "supplier.name",
    },
    {
      header: "Requested By",
      accessor: "requestedBy.username",
    },
    {
      header: "Date Purchased",
      accessor: "datePurchased",
      Cell: (children) => children && format(new Date(children), "dd MMM yyyy"),
    },
    {
      header: "Total",
      accessor: "total",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Reimbursed To",
      accessor: "reimbursedTo",
    },
    {
      header: "Sales Date",
      accessor: "salesDate",
      Cell: (children) => children && format(new Date(children), "dd MMM yyyy"),
    },
    {
      header: "Remarks",
      accessor: "remarks",
    },
    {
      header: "Items",
      accessor: "purchaseOrderItems",
      Cell: (children, index) => {
        const purchaseOrderItemsColumns = [
          {
            header: "Quantity",
            accessor: "quantity",
            Cell: (children) => children && children.toLocaleString(),
          },
          {
            header: "Unit",
            accessor: "item.unit",
          },
          {
            header: "Item",
            accessor: "item.name",
          },
          {
            header: "Price",
            accessor: "price",
            Cell: (children) => children && formatMoney(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={purchaseOrderItemsColumns}
          />
        );
      },
    },
  ];

  if (loading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Typography variant="h3" color="primary" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
        >
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleClearDateFilters={handleClearDateFilters}
          />

          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={purchaseOrders} columns={columns} />
        </Paper>
        <Pagination
          query={PURCHASE_ORDERS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </AdminListContainer>
    </>
  );
};

export default PurchaseOrdersPage;
