import React, { useState } from "react";

const DialogContext = React.createContext();

DialogContext.displayName = "DialogContext";

const DialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogComponent, setDialogComponent] = useState(<></>);

  const handleDialogOpen = (component = <></>) => {
    setDialogComponent(component);
    setDialogOpen(true);
  };

  const handleDialogClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDialogOpen(false);
  };
  return (
    <DialogContext.Provider
      value={{
        dialogOpen,
        dialogComponent,
        handleDialogOpen,
        handleDialogClose,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

const useDialogContext = () => {
  return React.useContext(DialogContext);
};

export { DialogProvider, useDialogContext };
