import { gql, useQuery } from "@apollo/client";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import useQueryString from "../../../hooks/useQueryString";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import DataTable from "../../common/DataTable";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

export const DISCOUNT_CARD_TYPES = gql`
  query DiscountCardType {
    __type(name: "DiscountCardType") {
      enumValues {
        name
      }
    }
  }
`;

export const DISCOUNT_CARDS = gql`
  query DiscountCards($skip: Int, $take: Int, $where: DiscountCardWhereInput) {
    discountCards(skip: $skip, take: $take, where: $where) {
      id
      discountCardType
      name
      number
      branch {
        id
        name
      }
    }
  }
`;

const DISCOUNT_CARDS_COUNT = gql`
  query DiscountCardsCount($where: DiscountCardWhereInput) {
    discountCardsCount(where: $where)
  }
`;

const DiscountCardsPage = () => {
  const title = `Discount Cards`;
  const { branchFilterQueryString } = useBranchContext();
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;

  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const {
    handleSubmitQueryString: handleSubmitFilter,
    initialQueryStringValue: filterQueryString,
  } = useQueryString("filter");

  const where = {
    ...(filterQueryString && {
      discountCardType: {
        equals: filterQueryString,
      },
    }),
    ...(branchFilterQueryString && {
      branch: {
        id: {
          equals: branchFilterQueryString,
        },
      },
    }),
    ...(searchQueryString && {
      OR: [
        {
          name: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
        {
          number: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
      ],
    }),
  };
  const { data: { discountCards } = {}, loading } = useQuery(DISCOUNT_CARDS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
    },
  });

  const {
    data: { __type: { enumValues: discountCardTypes } = {} } = {},
    loading: discountCardTypesLoading,
  } = useQuery(DISCOUNT_CARD_TYPES, {
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Branch",
      accessor: "branch.name",
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Number",
      accessor: "number",
    },
    {
      header: "Discount Card Type",
      accessor: "discountCardType",
      Cell: (children) => children && children.replace(/_/g, " "),
    },
  ];
  if (loading || discountCardTypesLoading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Typography variant="h3" color="primary" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="flex-end"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
        >
          <FormControl variant="outlined" size="small">
            <InputLabel id="discountCardType-label">
              Discount Card Type
            </InputLabel>
            <Select
              labelId="discountCardType-label"
              label="Discount Card Type"
              id="discountCardType"
              name="discountCardType"
              value={filterQueryString ?? ""}
              onChange={(e) => {
                handleSubmitFilter("direct")(e);
              }}
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                minWidth: "300px",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {discountCardTypes?.map(({ name }) => (
                <MenuItem key={name} value={name}>
                  {name.replace(/_/g, " ")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>

        <Paper className="dataTableContainer">
          <DataTable data={discountCards} columns={columns} />
        </Paper>
        <Pagination
          query={DISCOUNT_CARDS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </AdminListContainer>
    </>
  );
};

export default DiscountCardsPage;
