import { Button, Collapse, Portal } from "@mui/material";
import React, { useState } from "react";
import NestedTable from "./NestedTable";

const DataTableAccordion = ({ title, data, index, columns }) => {
  const [collapse, setCollapse] = useState([]);
  return (
    <div>
      {collapse?.includes(index) ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setCollapse(collapse.filter((item) => item !== index));
          }}
        >
          {title}
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setCollapse([...collapse, index]);
          }}
        >
          {title}
        </Button>
      )}
      <Portal container={document.querySelector(`#portal-${index}`)}>
        <Collapse in={collapse.includes(index)} timeout="auto" unmountOnExit>
          <NestedTable columns={columns} data={data} />
        </Collapse>
      </Portal>
    </div>
  );
};

export default DataTableAccordion;
