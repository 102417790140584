import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useInputChange } from "../../../hooks/useInputChange";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import ItemForm from "./ItemForm";

export const ITEM = gql`
  query Item($where: ItemWhereUniqueInput!) {
    item(where: $where) {
      id
      name
      unit
      threshold
      inventoryOrderNumber
      inventorySchedule
      itemCategory {
        id
        name
      }
    }
  }
`;

const ViewItemPage = () => {
  const title = `View Item`;
  let { id } = useParams();

  const { state, setState } = useInputChange({});

  const { data: { item } = {}, loading } = useQuery(ITEM, {
    fetchPolicy: "network-only",
    variables: {
      where: { id },
    },
  });

  useEffect(() => {
    if (item) {
      setState({ ...item });
    }
  }, [item, setState]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <ItemForm title={title} state={state} readOnly={true} />
    </>
  );
};

export default ViewItemPage;
