import { gql, useQuery } from "@apollo/client";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { endOfDay, format, startOfDay } from "date-fns";
import queryString from "query-string";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../contexts/branchContext";
import useDateFilter from "../hooks/useDateFilter";
import useQueryString from "../hooks/useQueryString";
import AdminListContainer from "../styles/components/AdminListContainer";
import iterateObjectValuesNullToBlank from "../utils/iterateObjectValuesNullToBlank";
import DataTable from "./common/DataTable";
import DateFilter from "./common/DateFilter";
import Loading from "./common/Loading";
import Meta from "./common/Meta";
import Pagination from "./common/Pagination";
import Search from "./common/Search";

export const ORDER_ITEMS = gql`
  query OrderItems(
    $skip: Int
    $take: Int
    $where: OrderItemWhereInput
    $orderBy: [OrderItemOrderByInput!]
  ) {
    orderItems(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      user {
        id
        username
      }
      createdAt
      tableNumber
      quantity
      menuItem {
        id
        name
        menuItemCategory
      }

      cookedAt
      isCooked
      servedAt
      isServed
      branch {
        id
        name
      }
    }
  }
`;

const ORDER_ITEMS_COUNT = gql`
  query OrderItemsCount($where: OrderItemWhereInput) {
    orderItemsCount(where: $where)
  }
`;

const OrderItemsPage = () => {
  const title = `Order Items`;
  const location = useLocation();
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;
  const csvLink = useRef(null);
  const [exportData, setExportData] = useState([]);
  const { branchFilterQueryString } = useBranchContext();

  const {
    handleChangeStartDate,
    handleChangeEndDate,
    handleClearDateFilters,
    startDate,
    endDate,
    startDateQueryString,
    endDateQueryString,
  } = useDateFilter();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const {
    handleSubmitQueryString: handleSubmitIsCooked,
    initialQueryStringValue: isCookedQueryString,
  } = useQueryString("isCooked");
  const {
    handleSubmitQueryString: handleSubmitIsServed,
    initialQueryStringValue: isServedQueryString,
  } = useQueryString("isServed");
  const where = {
    ...(branchFilterQueryString && {
      branch: {
        id: {
          equals: branchFilterQueryString,
        },
      },
    }),
    ...(startDate &&
      endDate && {
        createdAt: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
        },
      }),
    ...(isCookedQueryString && {
      isCooked: {
        equals: isCookedQueryString === "Yes" ? true : false,
      },
    }),
    ...(isServedQueryString && {
      isServed: {
        equals: isServedQueryString === "Yes" ? true : false,
      },
    }),
    ...(searchQueryString && {
      OR: [
        {
          user: {
            username: {
              contains: searchQueryString,
              mode: "insensitive",
            },
          },
        },
        {
          menuItem: {
            name: {
              contains: searchQueryString,
              mode: "insensitive",
            },
          },
        },
      ],
    }),
  };
  const {
    data: { orderItems } = {},
    loading,
    refetch,
  } = useQuery(ORDER_ITEMS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
      orderBy: [{ createdAt: "desc" }],
    },
  });

  const handleDownload = async () => {
    const { data: { orderItems } = {} } = await refetch({
      skip: null,
      take: null,
      where,
    });

    setExportData(
      orderItems.map((orderItem) => {
        return {
          ...iterateObjectValuesNullToBlank({
            ...orderItem,
            createdAt: format(
              new Date(orderItem.createdAt),
              "dd MMM yyyy hh:mm:ss a"
            ),
            cookedAt:
              orderItem.cookedAt &&
              format(new Date(orderItem.cookedAt), "dd MMM yyyy hh:mm:ss a"),
            servedAt:
              orderItem.servedAt &&
              format(new Date(orderItem.servedAt), "dd MMM yyyy hh:mm:ss a"),
          }),
        };
      })
    );
    csvLink.current.link.click();
  };

  const columns = [
    {
      header: "Branch",
      accessor: "branch.name",
    },
    {
      header: "User",
      accessor: "user.username",
    },
    {
      header: "Created At",
      accessor: "createdAt",
      Cell: (children) =>
        children && format(new Date(children), "dd MMM yyyy hh:mm:ss a"),
    },
    {
      header: "Table No.",
      accessor: "tableNumber",
    },
    {
      header: "Quantity",
      accessor: "quantity",
    },
    {
      header: "Menu Item",
      accessor: "menuItem.name",
    },
    {
      header: "Cooked At",
      accessor: "cookedAt",
      Cell: (children) =>
        children && format(new Date(children), "dd MMM yyyy hh:mm:ss a"),
    },
    {
      header: "Served At",
      accessor: "servedAt",
      Cell: (children) =>
        children && format(new Date(children), "dd MMM yyyy hh:mm:ss a"),
    },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <CSVLink
        component="button"
        headers={columns
          .filter(({ accessor }) => accessor !== "id")
          .map((column) => {
            return { label: column.header, key: column.accessor };
          })}
        filename={`${location.pathname}-${
          searchQueryString ? searchQueryString + "-" : ""
        }${format(Date.now(), "yyyy-MM-dd-HH:mm:ss")}.csv`}
        data={exportData}
        ref={csvLink}
      />
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Button
          onClick={handleDownload}
          variant="contained"
          startIcon={<DownloadIcon />}
          sx={{ marginLeft: "auto" }}
        >
          Download
        </Button>

        <Typography variant="h3" color="primary" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
        >
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleClearDateFilters={handleClearDateFilters}
          />

          <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
            <FormControl variant="outlined" size="small">
              <InputLabel id="isCooked-label">Is Cooked?</InputLabel>
              <Select
                labelId="isCooked-label"
                label="Is Cooked?"
                id="isCooked"
                name="isCooked"
                value={isCookedQueryString ?? ""}
                onChange={(e) => {
                  handleSubmitIsCooked("direct")(e);
                }}
                sx={{
                  backgroundColor: (theme) => theme.palette.common.white,
                  minWidth: "150px",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" size="small">
              <InputLabel id="isServed-label">Is Served?</InputLabel>
              <Select
                labelId="isServed-label"
                label="Is Served?"
                id="isServed"
                name="isServed"
                value={isServedQueryString ?? ""}
                onChange={(e) => {
                  handleSubmitIsServed("direct")(e);
                }}
                sx={{
                  backgroundColor: (theme) => theme.palette.common.white,
                  minWidth: "150px",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
            </FormControl>
            <Search
              handleChangeSearch={handleChangeSearch}
              handleSubmitSearch={handleSubmitSearch()}
              searchTerm={searchTerm}
            />
          </Stack>
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={orderItems} columns={columns} />
        </Paper>
        <Pagination
          query={ORDER_ITEMS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </AdminListContainer>
    </>
  );
};

export default OrderItemsPage;
