import React from "react";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import MUIPagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useQuery } from "@apollo/client";

const Pagination = ({
  query,
  skip = false,
  variables = {},
  page,
  perPage,
  sx = {},
}) => {
  let navigate = useNavigate();
  let location = useLocation();
  const paramsObj = queryString.parse(location.search);
  const [currentPage, setCurrentPage] = React.useState(page);
  const { data, loading, error } = useQuery(query, {
    fetchPolicy: "network-only",
    skip,
    variables,
  });
  if (loading || error || !data) return null;
  const count = Math.ceil(data[Object.keys(data)[0]] / perPage);
  const handleChange = (event, value) => {
    setCurrentPage(value);
    const newSearch = { ...paramsObj, page: value };
    navigate({
      pathname: `${location.pathname}`,
      search: queryString.stringify(newSearch),
    });
  };

  return (
    <Stack spacing={2} sx={{ mt: "auto", pt: 2, mx: "auto", ...sx }}>
      <MUIPagination
        count={count}
        page={currentPage}
        onChange={handleChange}
        color="secondary"
      />
    </Stack>
  );
};

export default Pagination;
