const stringToUrl = (string, prepend = "", append = "") => {
  return (
    prepend +
    "/" +
    string
      .toLowerCase()
      .replace(/[^a-zA-z ]/g, " ")
      .replace(/\s+/g, "-") +
    append
  );
};

const stringToSlug = (string) =>
  string.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");

export { stringToUrl, stringToSlug };
