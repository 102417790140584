import { gql, useQuery } from "@apollo/client";
import { Paper, Stack, Typography } from "@mui/material";
import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import useQueryString from "../../../hooks/useQueryString";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import DataTable from "../../common/DataTable";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

export const SUPPLIERS = gql`
  query Suppliers($skip: Int, $take: Int, $where: SupplierWhereInput) {
    suppliers(skip: $skip, take: $take, where: $where) {
      id
      name
      contactNumber
      contactPerson
      branch {
        id
        name
      }
    }
  }
`;

const SUPPLIERS_COUNT = gql`
  query SuppliersCount($where: SupplierWhereInput) {
    suppliersCount(where: $where)
  }
`;

const SuppliersPage = () => {
  const title = `Suppliers`;
  const { branchFilterQueryString } = useBranchContext();
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;

  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");

  const where = {
    ...(branchFilterQueryString && {
      branch: {
        id: {
          equals: branchFilterQueryString,
        },
      },
    }),
    ...(searchQueryString && {
      OR: [
        {
          name: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
        {
          contactPerson: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
      ],
    }),
  };
  const { data: { suppliers } = {}, loading } = useQuery(SUPPLIERS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
    },
  });

  const columns = [
    {
      header: "Branch",
      accessor: "branch.name",
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Contact Person",
      accessor: "contactPerson",
    },
    {
      header: "Contact Number",
      accessor: "contactNumber",
    },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
          mt={1}
          mb={1}
        >
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Search
              handleChangeSearch={handleChangeSearch}
              handleSubmitSearch={handleSubmitSearch()}
              searchTerm={searchTerm}
            />
          </Stack>
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={suppliers} columns={columns} />
        </Paper>
        <Pagination
          query={SUPPLIERS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </AdminListContainer>
    </>
  );
};

export default SuppliersPage;
