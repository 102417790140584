import { gql, useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import React from "react";
import { CURRENT_USER } from "../../contexts/authContext";
import { useSnackbarContext } from "../../contexts/snackbarContext";

const SIGNOUT = gql`
  mutation Signout {
    signout
  }
`;

const SignoutButton = ({ callback = () => {}, variant = "text" }) => {
  const { handleSnackbarOpen } = useSnackbarContext();

  const [signout] = useMutation(SIGNOUT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CURRENT_USER }],
    onError: (error) => {
      handleSnackbarOpen({
        message: "Log out error! " + error,
        severity: "error",
      });
    },
  });

  const handleClick = async () => {
    await signout();
  };
  return (
    <Button
      variant={variant}
      sx={{ mt: 1, color: (theme) => theme.palette.secondary.main }}
      fullWidth
      onClick={handleClick}
    >
      Sign Out
    </Button>
  );
};

export default SignoutButton;
