import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import formatMoney from "../../../utils/formatMoney";
import FormContainer from "../../common/FormContainer";
import Loading from "../../common/Loading";
import NestedTable from "../../common/NestedTable";
import { ITEM_CATEGORIES } from "../item_categories/ItemCategoriesPage";
import { INVENTORY_SCHEDULES } from "./ItemsPage";

const LATEST_PURCHASE_ORDER_ITEMS = gql`
  query LatestPurchaseOrderItems($itemId: String!) {
    latestPurchaseOrderItems(itemId: $itemId) {
      date
      supplier
      price
    }
  }
`;
const ItemForm = ({
  title = "",
  state = {},
  handleChange = () => {},
  handleSubmit = () => {},
  mutationLoading = false,
  readOnly = false,
}) => {
  const {
    data: { __type: { enumValues: inventorySchedules } = {} } = {},
    loading: inventorySchedulesLoading,
  } = useQuery(INVENTORY_SCHEDULES, {
    fetchPolicy: "network-only",
  });

  const { data: { itemCategories } = {}, loading: itemCategoriesLoading } =
    useQuery(ITEM_CATEGORIES, {
      fetchPolicy: "network-only",
    });
  const {
    data: { latestPurchaseOrderItems } = {},
    loading: latestPurchaseOrderItemsLoading,
  } = useQuery(LATEST_PURCHASE_ORDER_ITEMS, {
    skip: !readOnly || !state.id,
    fetchPolicy: "network-only",
    variables: {
      itemId: state?.id,
    },
  });

  const latestPurchaseOrderItemsColumns = [
    {
      header: "Date",
      accessor: "date",
      Cell: (children) => children && format(new Date(children), "dd MMM yyyy"),
    },
    { header: "Supplier", accessor: "supplier" },
    {
      header: "Price",
      accessor: "price",
      Cell: (children) => children && formatMoney(children),
    },
  ];
  if (
    inventorySchedulesLoading ||
    itemCategoriesLoading ||
    latestPurchaseOrderItemsLoading
  )
    return <Loading />;

  return (
    <FormContainer maxWidth="sm">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: "uppercase", fontWeight: "bold" }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel id="itemCategory-label">Item category</InputLabel>
              <Select
                inputProps={{
                  readOnly,
                }}
                labelId="itemCategory-label"
                label="Item category*"
                id="itemCategory"
                name="itemCategory"
                value={
                  itemCategories.find(
                    (itemCategory) =>
                      itemCategory?.id === state.itemCategory?.id
                  ) ?? ""
                }
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {itemCategories.map((itemCategory) => {
                  return (
                    <MenuItem key={itemCategory.id} value={itemCategory}>
                      {itemCategory.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="inventorySchedule-label">
                Inventory schedule
              </InputLabel>
              <Select
                inputProps={{
                  readOnly,
                }}
                labelId="inventorySchedule-label"
                label="Inventory schedule"
                id="inventorySchedule"
                name="inventorySchedule"
                value={state.inventorySchedule ?? ""}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {inventorySchedules.map(({ name }) => {
                  return (
                    <MenuItem key={name} value={name}>
                      {name.replace(/_/g, " ")}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              inputProps={{
                readOnly,
              }}
              fullWidth
              value={state.inventoryOrderNumber ?? ""}
              id="inventoryOrderNumber"
              label="Inventory order number"
              name="inventoryOrderNumber"
              autoComplete="inventoryOrderNumber"
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              inputProps={{
                readOnly,
              }}
              required
              fullWidth
              value={state.name ?? ""}
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              inputProps={{
                readOnly,
              }}
              fullWidth
              value={state.unit ?? ""}
              id="unit"
              label="Unit"
              name="unit"
              autoComplete="unit"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              inputProps={{
                readOnly,
              }}
              fullWidth
              value={state.threshold ?? ""}
              id="threshold"
              label="Threshold"
              name="threshold"
              autoComplete="threshold"
              onChange={handleChange}
              type="number"
            />
          </Grid>
        </Grid>
        {readOnly && (
          <>
            <Typography sx={{ mt: 1 }} variant="h5">
              Latest Purchase Order Items
            </Typography>
            <NestedTable
              columns={latestPurchaseOrderItemsColumns}
              data={latestPurchaseOrderItems}
            />
          </>
        )}
        {!readOnly && (
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            disabled={mutationLoading}
          >
            Submit
          </Button>
        )}
      </Box>
    </FormContainer>
  );
};

export default ItemForm;
