import { gql, useQuery } from "@apollo/client";
import { Paper, Stack, Typography } from "@mui/material";
import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import useQueryString from "../../../hooks/useQueryString";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import DataTable from "../../common/DataTable";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

export const ITEM_CATEGORIES = gql`
  query ItemCategories($skip: Int, $take: Int, $where: ItemCategoryWhereInput) {
    itemCategories(skip: $skip, take: $take, where: $where) {
      id
      name
      branch {
        id
        name
      }
    }
  }
`;

const ITEM_CATEGORIES_COUNT = gql`
  query ItemCategoriesCount($where: ItemCategoryWhereInput) {
    itemCategoriesCount(where: $where)
  }
`;

const ItemCategoriesPage = () => {
  const title = `Item Categories`;
  const { branchFilterQueryString } = useBranchContext();
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;

  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");

  const where = {
    ...(branchFilterQueryString && {
      branch: {
        id: {
          equals: branchFilterQueryString,
        },
      },
    }),
    ...(searchQueryString && {
      OR: [
        {
          name: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
      ],
    }),
  };
  const { data: { itemCategories } = {}, loading } = useQuery(ITEM_CATEGORIES, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
    },
  });

  const columns = [
    {
      header: "Branch",
      accessor: "branch.name",
    },
    {
      header: "Name",
      accessor: "name",
    },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Typography variant="h3" color="primary" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="flex-end"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
        >
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={itemCategories} columns={columns} />
        </Paper>
        <Pagination
          query={ITEM_CATEGORIES_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </AdminListContainer>
    </>
  );
};

export default ItemCategoriesPage;
