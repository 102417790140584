import { gql, useQuery } from "@apollo/client";
import { Paper, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import DataTable from "../../common/DataTable";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import { BRANCHES } from "../branches/BranchesPage";

export const ERRORLOGS = gql`
  query Errorlogs {
    errorlogs {
      id
      timestamp
      branchId
      message
    }
  }
`;

const ErrorlogsPage = () => {
  const title = `Error Logs`;

  const { data: { errorlogs } = {}, loading } = useQuery(ERRORLOGS, {
    fetchPolicy: "network-only",
  });

  const { data: { branches } = {}, loading: branchesLoading } = useQuery(
    BRANCHES,
    {
      fetchPolicy: "network-only",
    }
  );

  const columns = [
    {
      header: "Branch",
      accessor: "branchId",
      Cell: (children) =>
        children && (
          <Typography>
            {branches?.find(({ id }) => id === children)?.name}
          </Typography>
        ),
    },
    {
      header: "Timestamp",
      accessor: "timestamp",
      Cell: (children) =>
        children && format(new Date(children), "dd MMM yyyy hh:mm:ss a"),
    },
    {
      header: "Message",
      accessor: "message",
    },
  ];
  if (loading || branchesLoading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
          mt={1}
          mb={1}
        >
          <Typography variant="h3" color="primary">
            {title}
          </Typography>
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={errorlogs} columns={columns} />
        </Paper>
      </AdminListContainer>
    </>
  );
};

export default ErrorlogsPage;
