import React, { useState } from "react";

const SnackbarContext = React.createContext();

SnackbarContext.displayName = "SnackbarContext";

const SnackbarProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(undefined);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSnackbarOpen = ({ message, severity }) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  return (
    <SnackbarContext.Provider
      value={{
        handleSnackbarOpen,
        handleSnackbarClose,
        snackbarOpen,
        snackbarSeverity,
        snackbarMessage,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

const useSnackbarContext = () => {
  return React.useContext(SnackbarContext);
};

export { SnackbarProvider, useSnackbarContext };
