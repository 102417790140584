import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const getNestedValue = (obj, path) =>
  path.split(".").reduce((acc, cur) => {
    return acc && acc[cur];
  }, obj);

const NestedTable = ({ data, columns, tableSummary = <></> }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {columns.map(({ header }) => (
            <TableCell align="center" key={header}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item, idx) => (
          <TableRow key={idx}>
            {columns.map(({ accessor, Cell = (children) => <>
                  {children}
                </> }, idx) => (
              <TableCell align="center" key={idx} size="medium">
                {Cell(getNestedValue(item, accessor))}
              </TableCell>
            ))}
          </TableRow>
        ))}
        {tableSummary}
      </TableBody>
    </Table>
  );
};

export default NestedTable;
