import React, { useState } from "react";
import TableSortLabel from "@mui/material/TableSortLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

const getNestedValue = (obj, path) =>
  path.split(".").reduce((acc, cur) => {
    return acc && acc[cur];
  }, obj);

const descendingComparator = (a, b, orderBy) => {
  if (getNestedValue(b, orderBy) < getNestedValue(a, orderBy)) {
    return -1;
  }
  if (getNestedValue(b, orderBy) > getNestedValue(a, orderBy)) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};
const DataTable = ({ data = [], columns = [], tableSummary = <></> }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      {data.length > 0 ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map(({ header, accessor }) => (
                <TableCell align="center" key={header}>
                  <TableSortLabel
                    active={orderBy === accessor}
                    direction={orderBy === accessor ? order : "asc"}
                    onClick={(event) => handleRequestSort(event, accessor)}
                  >
                    {header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map(
              (item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      {columns.map(({ accessor, Cell = (children) => <>
                            {children}
                          </> }, idx) => (
                        <TableCell align="center" key={idx}>
                          {Cell(getNestedValue(item, accessor), index)}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        id={`portal-${index}`}
                        colSpan={columns.length}
                        padding="none"
                        style={{ borderBottom: 0 }}
                      ></TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              }
            )}
            {tableSummary}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="h6" align="center" sx={{ my: 2 }}>
          No items here
        </Typography>
      )}
    </>
  );
};

export default DataTable;
