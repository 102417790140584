import { styled } from "@mui/material";

const AdminListContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(1, 0),
  flex: 1,
  display: "flex",
  flexDirection: "column",

  "& .actionButton": {
    marginLeft: "auto",
    fontWeight: "bold",
  },

  "& .dataTableContainer": {
    overflowX: "auto",
    overflowY: "hidden",
    margin: theme.spacing(2, 0),
  },
}));

export default AdminListContainer;
