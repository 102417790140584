import { useState } from "react";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router";

const useQueryString = (field = "", callback = () => {}) => {
  let navigate = useNavigate();
  let location = useLocation();
  const parsed = queryString.parse(location.search);
  const initialQueryStringValue = parsed[field] || undefined;

  const [queryStringValue, setQueryStringValue] = useState(
    initialQueryStringValue
  );

  const handleChangeQueryString = (e, newValue = null) => {
    const value = newValue || e.target.value;
    setQueryStringValue(value);
  };
  const parseValue = (field, value) => {
    if (!value) {
      delete parsed[field];
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({ ...parsed }),
      });
    } else {
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({ ...parsed, [field]: value }),
      });
    }
  };

  const handleSubmitQueryString =
    (type = "indirect", newValue = null) =>
    (e) => {
      e.preventDefault();
      const value = newValue || e.target.value;
      if (type === "direct") {
        setQueryStringValue(value);
        parseValue(field, value);
        callback();
      } else {
        delete parsed["page"];
        parseValue(field, queryStringValue);
      }
    };

  return {
    handleChangeQueryString,
    handleSubmitQueryString,
    queryStringValue,
    initialQueryStringValue,
  };
};

export default useQueryString;
