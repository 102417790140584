import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { stringToUrl } from "../../utils/stringMethods";

const links = [
  {
    group: "General",
    routes: [
      { name: "Branches" },
      { name: "Users" },
      { name: "Product Categories" },
      { name: "Products" },
      { name: "Menu Items" },
    ],
  },
  {
    group: "Sales",
    routes: [
      { name: "Pending Bills" },
      { name: "Billings" },
      { name: "Discount Cards" },
      { name: "Order Items" },
    ],
  },
  {
    group: "Staffs",
    routes: [{ name: "Employees" }, { name: "Attendances" }],
  },
  {
    group: "Back Office",
    routes: [
      { name: "Item Categories" },
      { name: "Items" },
      { name: "Suppliers" },
      { name: "Purchase Orders" },
      { name: "Transfer Slips" },
      { name: "Wastages" },
      { name: "Inventories" },
      { name: "Ingredients" },
    ],
  },
  {
    group: "Reports",
    routes: [
      { name: "Inventory Report" },
      { name: "Tip Calculator" },
      { name: "Sales Report" },
      { name: "Error Logs" },
    ],
  },
];
const NavLinks = ({ handleDrawerClose }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{ width: 300 }}
      onClick={handleDrawerClose}
      onKeyDown={handleDrawerClose}
    >
      <List component="nav">
        {links.map((link, idx) => (
          <div key={idx}>
            <ListSubheader
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.common.white,
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              {link.group}
            </ListSubheader>
            {link.routes.map((route, idx) => (
              <ListItemButton
                key={idx}
                onClick={() => navigate(stringToUrl(route.name))}
              >
                <ListItemText disableTypography inset>
                  {route.name}
                </ListItemText>
              </ListItemButton>
            ))}
          </div>
        ))}
      </List>
    </Box>
  );
};

export default NavLinks;
