const handleNestedAdd = (state, setState, value) => {
  setState(state.concat(value));
};
const handleNestedSubtract = (state, setState, idx) => {
  setState(state.filter((item, nidx) => idx !== nidx));
};
const handleNestedChange = (state, setState, e, idx) => {
  const target = e.target;
  const name = target.name;
  let value;

  switch (target.type) {
    case "number":
      if (target.value === "") {
        value = null;
      } else if (Object.is(NaN, parseFloat(target.value))) {
        value = target.value;
      } else {
        value = parseFloat(target.value);
      }
      break;
    default:
      value = target.value;
  }

  const newItems = state.map((item, nidx) => {
    if (idx !== nidx) return item;
    return { ...item, [name]: value };
  });

  setState(newItems);
};

export { handleNestedAdd, handleNestedSubtract, handleNestedChange };
