import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { startOfDay } from "date-fns";
import { endOfDay } from "date-fns/esm";
import React, { useEffect, useMemo } from "react";
import { useInputChange } from "../../../hooks/useInputChange";
import formatMoney from "../../../utils/formatMoney";
import { handleNestedChange } from "../../../utils/handleNestedInputChange";
import FormContainer from "../../common/FormContainer";
import Loading from "../../common/Loading";
import { BRANCHES } from "../branches/BranchesPage";

const TIP_CALCULATOR_ITEMS = gql`
  query TipCalculatorItems($where: AttendanceWhereInput) {
    tipCalculatorItems(where: $where) {
      firstName
      lastName
      middleName
      numberOfDays
    }
  }
`;
const TipCalculatorPage = ({ title = "Tip Calculator" }) => {
  const { state, setState, handleChange } = useInputChange();
  const {
    state: tipCalculatorItemsState,
    setState: tipCalculatorItemsSetState,
  } = useInputChange({}, []);

  const { data: { branches } = {}, loading: branchesLoading } = useQuery(
    BRANCHES,
    {
      fetchPolicy: "network-only",
    }
  );
  const where = useMemo(() => {
    return {
      dateTime: {
        gte: startOfDay(new Date(state?.startDate)),
        lte: endOfDay(new Date(state?.endDate)),
      },
      branchId: {
        equals: state?.branch?.id,
      },
    };
  }, [state.startDate, state.endDate, state.branch?.id]);

  const { data: { tipCalculatorItems } = {}, refetch } = useQuery(
    TIP_CALCULATOR_ITEMS,
    {
      fetchPolicy: "network-only",
      skip: !state.startDate || !state.endDate || !state.branch,
      variables: {
        where,
      },
    }
  );

  const handleChangeItem = (e, idx) =>
    handleNestedChange(
      tipCalculatorItemsState,
      tipCalculatorItemsSetState,
      e,
      idx
    );

  const handleSubmit = (e) => {
    e.preventDefault();
    const tipPerDay = Math.floor(
      (state.totalTip - state.deductions + state.carryOverPrevious) /
        tipCalculatorItemsState.reduce((acc, cur) => {
          if (!cur.numberOfDays) return acc;
          return acc + cur.numberOfDays;
        }, 0)
    );

    const totalTipDistributed = tipCalculatorItemsState.reduce((acc, cur) => {
      if (!tipPerDay || !cur.numberOfDays) return acc;
      return acc + tipPerDay * cur.numberOfDays;
    }, 0);

    const carryOverNext =
      state.totalTip -
      state.deductions +
      state.carryOverPrevious -
      totalTipDistributed;
    setState({ ...state, tipPerDay, totalTipDistributed, carryOverNext });
    tipCalculatorItemsSetState(
      tipCalculatorItemsState.map((item) => {
        return { ...item, tip: tipPerDay * item.numberOfDays };
      })
    );
  };
  useEffect(() => {
    if (state.startDate && state.endDate && state.branch) {
      refetch({
        where,
      });
    }
  }, [state.startDate, state.endDate, state.branch, refetch, where]);
  useEffect(() => {
    if (tipCalculatorItems) {
      tipCalculatorItemsSetState(tipCalculatorItems);
    }
  }, [tipCalculatorItemsSetState, tipCalculatorItems]);
  if (branchesLoading) return <Loading />;

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: "uppercase", fontWeight: "bold" }}
        gutterBottom
      >
        {title}
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              required
              id="startDate"
              label="Start date"
              name="startDate"
              value={state.startDate ?? ""}
              onChange={handleChange}
              fullWidth
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              required
              id="endDate"
              label="End date"
              name="endDate"
              value={state.endDate ?? ""}
              onChange={handleChange}
              fullWidth
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth required>
              <InputLabel id="branch-label">Branch</InputLabel>
              <Select
                labelId="branch-label"
                label="Branch"
                id="branch"
                name="branch"
                value={state.branch ?? ""}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {branches?.map((branch) => (
                  <MenuItem key={branch.name} value={branch}>
                    {branch.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              required
              fullWidth
              id="totalTip"
              label="Total tip"
              name="totalTip"
              value={state.totalTip ?? ""}
              onChange={handleChange}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₱</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              required
              fullWidth
              id="deductions"
              label="Deductions"
              name="deductions"
              value={state.deductions ?? ""}
              onChange={handleChange}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₱</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              required
              fullWidth
              id="carryOverPrevious"
              label="Previous month carry over"
              name="carryOverPrevious"
              value={state.carryOverPrevious ?? ""}
              onChange={handleChange}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₱</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="tipPerDay"
              label="Tip per day"
              name="tipPerDay"
              value={state?.tipPerDay ?? ""}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="totalTipDistributed"
              label="Total tip distributed"
              name="totalTipDistributed"
              value={state?.totalTipDistributed ?? ""}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="carryOverNext"
              label="Next month carry over"
              name="carryOverNext"
              value={state?.carryOverNext ?? ""}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <Typography variant="h5" sx={{ my: 1 }} gutterBottom>
          Employees
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, minmax(150px, 1fr))",
            alignItems: "center",
            justifyItems: "center",
            gap: 1,
            overflow: "auto",
          }}
        >
          <Typography align="center" variant="h6">
            Last Name
          </Typography>

          <Typography align="center" variant="h6">
            First Name
          </Typography>

          <Typography align="center" variant="h6">
            Middle Name
          </Typography>

          <Typography align="center" variant="h6">
            Number of Days
          </Typography>

          <Typography align="center" variant="h6">
            Tip
          </Typography>

          {tipCalculatorItemsState?.map((tipCalculatorItem, idx) => {
            return (
              <React.Fragment key={idx}>
                <Typography align="center">
                  {tipCalculatorItem?.lastName ?? ""}
                </Typography>

                <Typography align="center">
                  {tipCalculatorItem?.firstName ?? ""}
                </Typography>

                <Typography align="center">
                  {tipCalculatorItem?.middleName ?? ""}
                </Typography>

                <TextField
                  size="small"
                  required
                  fullWidth
                  id="numberOfDays"
                  name="numberOfDays"
                  value={tipCalculatorItem.numberOfDays ?? ""}
                  onChange={(e) => handleChangeItem(e, idx)}
                  type="number"
                />

                <Typography align="center">
                  {tipCalculatorItem?.tip
                    ? formatMoney(tipCalculatorItem?.tip)
                    : ""}
                </Typography>
              </React.Fragment>
            );
          })}
        </Box>

        <Button type="submit" variant="contained" fullWidth sx={{ mt: 3 }}>
          Submit
        </Button>
      </Box>
    </FormContainer>
  );
};

export default TipCalculatorPage;
