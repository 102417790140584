import { gql, useMutation } from "@apollo/client";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CURRENT_USER } from "../../contexts/authContext";
import { useSnackbarContext } from "../../contexts/snackbarContext";
import { useInputChange } from "../../hooks/useInputChange";
import logoWhite from "../../images/logos/logo-white.png";

const SIGN_IN = gql`
  mutation Signin($username: String!, $password: String!) {
    signin(username: $username, password: $password) {
      id
      username
    }
  }
`;

const SigninPage = () => {
  const title = `Sign In`;
  const navigate = useNavigate();
  const { state, handleChange } = useInputChange();
  const { handleSnackbarOpen } = useSnackbarContext();
  const [showPassword, setShowPassword] = useState(false);

  const [signin, { loading: mutationLoading }] = useMutation(SIGN_IN, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CURRENT_USER }],

    onError: (error) =>
      handleSnackbarOpen({
        message:
          title.charAt(0).toUpperCase() +
          title.slice(1).toLowerCase() +
          " error! " +
          error,
        severity: "error",
      }),
    onCompleted: () => {
      navigate("/");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    signin({
      variables: {
        ...state,
      },
    });
  };

  return (
    <Container maxWidth="xs" sx={{ display: "flex", flexGrow: 1 }}>
      <Paper sx={{ m: "auto" }}>
        <Box sx={{ padding: (theme) => theme.spacing(2) }}>
          <Box
            component="img"
            src={logoWhite}
            alt="logo"
            sx={{
              height: "80px",
              display: "block",
              margin: (theme) => `${theme.spacing(1)} auto`,
            }}
          />
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    name="password"
                    label="Password*"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 3, color: (theme) => theme.palette.secondary.main }}
              disabled={mutationLoading}
            >
              Log In
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default SigninPage;
