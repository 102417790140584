import { gql, useQuery } from "@apollo/client";
import DownloadIcon from "@mui/icons-material/Download";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import useDateFilter from "../../../hooks/useDateFilter";
import useQueryString from "../../../hooks/useQueryString";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import formatMoney from "../../../utils/formatMoney";
import iterateObjectValuesNullToBlank from "../../../utils/iterateObjectValuesNullToBlank";
import DataTable from "../../common/DataTable";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Search from "../../common/Search";
import { ITEM_CATEGORIES } from "../item_categories/ItemCategoriesPage";

export const INVENTORY_REPORT_ITEMS = gql`
  query InventoryReportItems(
    $where: ItemWhereInput
    $branchId: String!
    $itemCategoryId: String!
    $date: DateTime!
  ) {
    inventoryReportItems(
      where: $where
      branchId: $branchId
      itemCategoryId: $itemCategoryId
      date: $date
    ) {
      item
      unit
      beginning
      purchased
      consumed
      transferred
      estimated
      actual
      variance
      phpValue
      lowOnStock
      inventoryPhpValue
    }
  }
`;

const InventoryReportPage = () => {
  const title = `Inventory Report`;
  const location = useLocation();
  const [exportData, setExportData] = useState([]);
  const csvLink = useRef(null);
  const { dateQueryString, date, handleChangeDate } = useDateFilter();
  const { branchFilterQueryString } = useBranchContext();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const {
    handleSubmitQueryString: handleSubmitItemCategoryFilter,
    initialQueryStringValue: itemCategoryFilterQueryString,
  } = useQueryString("itemCategoryId");

  const where = {
    ...(searchQueryString && {
      OR: [
        {
          name: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
      ],
    }),
  };

  const skipCriteria =
    !itemCategoryFilterQueryString ||
    !branchFilterQueryString ||
    !dateQueryString;
  const {
    data: { inventoryReportItems } = {},
    loading,
    refetch,
  } = useQuery(INVENTORY_REPORT_ITEMS, {
    fetchPolicy: "network-only",
    skip: skipCriteria,
    variables: {
      where,
      ...(dateQueryString && {
        date: new Date(dateQueryString),
      }),
      ...(itemCategoryFilterQueryString && {
        itemCategoryId: itemCategoryFilterQueryString,
      }),
      ...(branchFilterQueryString && {
        branchId: branchFilterQueryString,
      }),
    },
  });

  const { data: { itemCategories } = {}, loading: itemCategoriesLoading } =
    useQuery(ITEM_CATEGORIES, {
      fetchPolicy: "network-only",
      skip: !branchFilterQueryString,
      variables: {
        where: {
          branchId: {
            equals: branchFilterQueryString,
          },
        },
      },
    });

  const handleDownload = async () => {
    const { data: { inventoryReportItems } = {} } = await refetch({
      skip: null,
      take: null,
      where,
    });

    setExportData(
      inventoryReportItems.map((inventoryReportItem) => {
        return {
          ...iterateObjectValuesNullToBlank({
            ...inventoryReportItem,
          }),
        };
      })
    );
    csvLink.current.link.click();
  };

  const columns = [
    {
      header: "Low on Stock",
      accessor: "lowOnStock",
      Cell: (children) =>
        children && (
          <Typography variant="h6" color="error">
            !
          </Typography>
        ),
    },
    {
      header: "Item",
      accessor: "item",
    },
    {
      header: "Unit",
      accessor: "unit",
    },
    {
      header: "Beginning",
      accessor: "beginning",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Purchased",
      accessor: "purchased",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Consumed",
      accessor: "consumed",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Transferred",
      accessor: "transferred",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Estimated",
      accessor: "estimated",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Actual",
      accessor: "actual",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Variance",
      accessor: "variance",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Variance Php Value",
      accessor: "phpValue",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Inventory Php Value",
      accessor: "inventoryPhpValue",
      Cell: (children) => children && formatMoney(children),
    },
  ];
  if (loading || itemCategoriesLoading) return <Loading />;
  return (
    <>
      <CSVLink
        component="button"
        headers={columns
          .filter(({ accessor }) => accessor !== "id")
          .map((column) => {
            return { label: column.header, key: column.accessor };
          })}
        filename={`${location.pathname}-${
          searchQueryString ? searchQueryString + "-" : ""
        }${dateQueryString}.csv`}
        data={exportData}
        ref={csvLink}
      />
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Button
          disabled={skipCriteria}
          onClick={handleDownload}
          variant="contained"
          startIcon={<DownloadIcon />}
          sx={{ marginLeft: "auto" }}
        >
          Download
        </Button>

        <Typography variant="h3" color="primary" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="date"
              name="date"
              label="Date"
              value={date || null}
              onChange={handleChangeDate}
              disableMaskedInput
              inputProps={{ readOnly: true }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  sx={{
                    backgroundColor: (theme) => theme.palette.common.white,
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <FormControl variant="outlined" size="small">
            <InputLabel id="itemCategory-label">Item Category</InputLabel>
            <Select
              labelId="itemCategory-label"
              label="Item Category"
              id="itemCategory"
              name="itemCategory"
              value={itemCategoryFilterQueryString ?? ""}
              onChange={(e) => {
                handleSubmitItemCategoryFilter("direct")(e);
              }}
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                minWidth: "300px",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {itemCategories?.map(({ id, name }) => (
                <MenuItem key={name} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={inventoryReportItems} columns={columns} />
        </Paper>
      </AdminListContainer>
    </>
  );
};

export default InventoryReportPage;
