import Button from "@mui/material/Button";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Grid, Stack, TextField } from "@mui/material";

const DateFilter = ({
  startDate,
  endDate,
  handleChangeStartDate,
  handleChangeEndDate,
  handleClearDateFilters,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
        <DatePicker
          id="startDate"
          name="startDate"
          label="Start Date"
          value={startDate || null}
          onChange={handleChangeStartDate}
          disableMaskedInput
          inputProps={{ readOnly: true }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
              }}
            />
          )}
        />
        <DatePicker
          id="endDate"
          name="endDate"
          label="End Date"
          value={endDate || null}
          onChange={handleChangeEndDate}
          inputProps={{ readOnly: true }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                borderRadius: "4px",
              }}
            />
          )}
        />
        <Button onClick={handleClearDateFilters}>Clear</Button>
      </Stack>
    </LocalizationProvider>
  );
};

export default DateFilter;
