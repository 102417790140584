import { gql, useQuery } from "@apollo/client";
import { Paper, Typography } from "@mui/material";
import { format } from "date-fns";
import queryString from "query-string";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import formatMoney from "../../../utils/formatMoney";
import DataTable from "../../common/DataTable";
import DataTableAccordion from "../../common/DataTableAccordion";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";

export const PENDING_BILLS = gql`
  query PendingBills(
    $skip: Int
    $take: Int
    $where: PendingBillWhereInput
    $orderBy: [PendingBillOrderByInput!]
  ) {
    pendingBills(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      tableNumber
      createdAt
      total
      totalNumberOfPax
      pendingBillItems {
        id
        quantity
        product {
          id
          price
          numberOfPax
        }
      }
      branch {
        id
        name
      }
    }
  }
`;

const PendingBillsPage = () => {
  const title = `Pending Bills`;
  const { branchFilterQueryString } = useBranchContext();
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;

  const where = {
    ...(branchFilterQueryString && {
      branch: {
        id: {
          equals: branchFilterQueryString,
        },
      },
    }),
  };
  const { data: { pendingBills } = {}, loading } = useQuery(PENDING_BILLS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
      orderBy: [{ createdAt: "desc" }],
    },
  });

  const columns = [
    {
      header: "Branch",
      accessor: "branch.name",
    },
    {
      header: "Created At",
      accessor: "createdAt",
      Cell: (children) =>
        children && format(new Date(children), "dd MMM yyyy hh:mm:ss a"),
    },
    {
      header: "Table Number",
      accessor: "tableNumber",
    },
    {
      header: "Total",
      accessor: "total",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "No. of Pax",
      accessor: "totalNumberOfPax",
      Cell: (children) => children && children.toLocaleString(),
    },
    {
      header: "Items",
      accessor: "pendingBillItems",
      Cell: (children, index) => {
        const pendingBillItemsColumns = [
          {
            header: "Quantity",
            accessor: "quantity",
            Cell: (children) => children && children.toLocaleString(),
          },
          {
            header: "Product",
            accessor: "product.name",
          },
          {
            header: "Price",
            accessor: "product.price",
            Cell: (children) => children && formatMoney(children),
          },
          {
            header: "No. of Pax",
            accessor: "product.numberOfPax",
            Cell: (children) => children && children.toLocaleString(),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={pendingBillItemsColumns}
          />
        );
      },
    },
  ];

  if (loading) return <Loading />;
  const pendingBillsTotalNumberOfPax = pendingBills?.reduce((acc, cur) => {
    if (!cur.totalNumberOfPax) return acc;
    return acc + cur.totalNumberOfPax;
  }, 0);
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Typography variant="h3" color="primary">
          {title}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Total No. of Pax: {pendingBillsTotalNumberOfPax}
        </Typography>

        <Paper className="dataTableContainer">
          <DataTable data={pendingBills} columns={columns} />
        </Paper>
      </AdminListContainer>
    </>
  );
};

export default PendingBillsPage;
