import { gql, useQuery } from "@apollo/client";
import { Paper, Stack, Typography } from "@mui/material";
import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import useQueryString from "../../../hooks/useQueryString";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import DataTable from "../../common/DataTable";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

export const RECIPES = gql`
  query Ingredients(
    $skip: Int
    $take: Int
    $where: IngredientWhereInput
    $orderBy: [IngredientOrderByInput!]
  ) {
    ingredients(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      item {
        id
        name
        unit
      }
      quantity
      menuItem {
        id
        name
      }
      branch {
        id
        name
      }
    }
  }
`;

const RECIPES_COUNT = gql`
  query IngredientsCount($where: IngredientWhereInput) {
    ingredientsCount(where: $where)
  }
`;

const IngredientsPage = () => {
  const title = `Ingredients`;
  const { branchFilterQueryString } = useBranchContext();

  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;

  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");

  const where = {
    ...(branchFilterQueryString && {
      branch: {
        id: {
          equals: branchFilterQueryString,
        },
      },
    }),
    ...(searchQueryString && {
      OR: [
        {
          menuItem: {
            name: {
              contains: searchQueryString,
              mode: "insensitive",
            },
          },
        },
        {
          item: {
            name: {
              contains: searchQueryString,
              mode: "insensitive",
            },
          },
        },
      ],
    }),
  };
  const { data: { ingredients } = {}, loading } = useQuery(RECIPES, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
      orderBy: [{ menuItemId: "asc" }],
    },
  });

  const columns = [
    {
      header: "Branch",
      accessor: "branch.name",
    },
    {
      header: "Menu Item",
      accessor: "menuItem.name",
    },
    {
      header: "Item",
      accessor: "item.name",
    },
    {
      header: "Quantity",
      accessor: "quantity",
      Cell: (children) => children && parseFloat(children).toFixed(6),
    },
    {
      header: "Unit",
      accessor: "item.unit",
    },
  ];

  if (loading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Typography variant="h3" color="primary" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="flex-end"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
        >
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={ingredients} columns={columns} />
        </Paper>
        <Pagination
          query={RECIPES_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </AdminListContainer>
    </>
  );
};

export default IngredientsPage;
