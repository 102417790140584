import { gql, useQuery } from "@apollo/client";
import { Link, Paper, Typography } from "@mui/material";
import { format, parse } from "date-fns";
import React from "react";
import { useBranchContext } from "../../../contexts/branchContext";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import DataTable from "../../common/DataTable";
import DataTableAccordion from "../../common/DataTableAccordion";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";

export const BRANCHES = gql`
  query Branches($where: BranchWhereInput) {
    branches(where: $where) {
      id
      name
      companyName
      address
      mobileNumber

      operatingHours {
        id
        dayOfTheWeek
        openingHour
        closingHour
      }
      seatPlanPhotoUrl
    }
  }
`;

const BranchesPage = () => {
  const title = `Branches`;
  const { branchFilterQueryString } = useBranchContext();

  const where = {
    ...(branchFilterQueryString && {
      id: {
        equals: branchFilterQueryString,
      },
    }),
  };
  const { data: { branches } = {}, loading } = useQuery(BRANCHES, {
    fetchPolicy: "network-only",
    variables: {
      where,
    },
  });

  const columns = [
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Company Name",
      accessor: "companyName",
    },
    {
      header: "Address",
      accessor: "address",
    },
    {
      header: "Mobile Number",
      accessor: "mobileNumber",
    },
    {
      header: "Seat Plan",
      accessor: "seatPlanPhotoUrl",
      Cell: (children) =>
        children && (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_BACKEND_URI}/${children}`}
          >
            File
          </Link>
        ),
    },
    {
      header: "Operating Hours",
      accessor: "operatingHours",
      Cell: (children, index) => {
        const operatingHoursColumns = [
          {
            header: "Day of the Week",
            accessor: "dayOfTheWeek",
            // Cell: (children) =>
            //   children &&
            //   children.replace(
            //     /(?:^\w|[A-Z]|\b\w|\s+)/g,
            //     function (match, index) {
            //       if (+match === 0) return "";
            //       return index === 0
            //         ? match.toUpperCase()
            //         : match.toLowerCase();
            //     }
            //   ),
          },
          {
            header: "Opening Hour",
            accessor: "openingHour",
            Cell: (children) =>
              children &&
              format(parse(children, "HH:mm", new Date()), "hh:mm:aa"),
          },
          {
            header: "Closing Hour",
            accessor: "closingHour",
            Cell: (children) =>
              children &&
              format(parse(children, "HH:mm", new Date()), "hh:mm:aa"),
          },
        ];
        return (
          <DataTableAccordion
            title="Hours"
            data={children}
            index={index}
            columns={operatingHoursColumns}
          />
        );
      },
    },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Typography variant="h3" color="primary">
          {title}
        </Typography>
        <Paper className="dataTableContainer">
          <DataTable data={branches} columns={columns} />
        </Paper>
      </AdminListContainer>
    </>
  );
};

export default BranchesPage;
