import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { Component } from "react";
import { withRouter } from "../../utils/withRouter";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "", errorInfo: "", expanded: false };
    this.handleExpandClick = this.handleExpandClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const locationChanged = this.props.location !== prevProps.location;
    if (locationChanged && this.state.hasError) {
      this.setState({
        hasError: false,
        error: "",
        errorInfo: "",
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    this.setState({ errorInfo });
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Card
          sx={{
            margin: "0 auto",
            width: (theme) => theme.breakpoints.values.sm,
          }}
        >
          <CardHeader
            title={
              <Typography variant="h6">
                There was an error loading this page.
              </Typography>
            }
            avatar={<WarningIcon color="secondary" />}
            disableTypography={true}
          />
          <CardContent color="textSecondary" sx={{ padding: "8px 16px" }}>
            <Grid container justify="space-between" alignItems="center">
              Click for error details
              <ExpandMore
                expand={this.state.expanded}
                onClick={this.handleExpandClick}
                aria-expanded={this.state.expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Grid>
          </CardContent>
          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography variant="subtitle2">
                {this.state.error.message}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {this.state.errorInfo &&
                  this.state.errorInfo.componentStack.toString()}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
