import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

const link = new createUploadLink({
  uri: `${process.env.REACT_APP_BACKEND_URI}`,
  credentials: "include",
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default apolloClient;
