import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";

const Search = ({ handleSubmitSearch, handleChangeSearch, searchTerm }) => {
  return (
    <>
      <form onSubmit={handleSubmitSearch}>
        <TextField
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            borderRadius: "4px",
            minWidth: "200px",
          }}
          value={searchTerm || ""}
          onChange={handleChangeSearch}
          id="search"
          size="small"
          placeholder="Search..."
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </form>
    </>
  );
};

export default Search;
