import { gql, useQuery } from "@apollo/client";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { endOfDay, startOfDay } from "date-fns";
import format from "date-fns/format";
import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import useDateFilter from "../../../hooks/useDateFilter";
import useQueryString from "../../../hooks/useQueryString";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import formatMoney from "../../../utils/formatMoney";
import DataTable from "../../common/DataTable";
import DataTableAccordion from "../../common/DataTableAccordion";
import DateFilter from "../../common/DateFilter";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";
import SalesSummary from "./SalesSummary";
export const BILLINGS = gql`
  query Billings(
    $skip: Int
    $take: Int
    $where: BillingWhereInput
    $orderBy: [BillingOrderByInput!]
  ) {
    billings(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      number
      tableNumber
      billingItems {
        id
        quantity
        product
        price
      }
      modeOfPayment
      subtotal
      serviceCharge
      discount
      netTotal
      amountReceived
      branch {
        id
        name
      }
    }
  }
`;

const SALES_SUMMARY_PER_PRODUCT = gql`
  query SalesSummaryPerProduct(
    $searchTerm: String
    $startDate: String
    $endDate: String
    $branchId: String
  ) {
    salesSummaryPerProduct(
      searchTerm: $searchTerm
      startDate: $startDate
      endDate: $endDate
      branchId: $branchId
    ) {
      product
      quantity
      subtotal
    }
  }
`;

const SALES_SUMMARY_TOTAL = gql`
  query SalesSummaryTotal(
    $searchTerm: String
    $startDate: String
    $endDate: String
    $branchId: String
  ) {
    salesSummaryTotal(
      searchTerm: $searchTerm
      startDate: $startDate
      endDate: $endDate
      branchId: $branchId
    ) {
      serviceCharge
      discount
      totalSales
      productCategoryType
    }
  }
`;

const SALES_SUMMARY_PER_MODE_OF_PAYMENT = gql`
  query SalesSummaryPerModeOfPayment(
    $searchTerm: String
    $startDate: String
    $endDate: String
    $branchId: String
  ) {
    salesSummaryPerModeOfPayment(
      searchTerm: $searchTerm
      startDate: $startDate
      endDate: $endDate
      branchId: $branchId
    ) {
      modeOfPayment
      netTotal
    }
  }
`;

const BILLINGS_COUNT = gql`
  query BillingsCount($where: BillingWhereInput) {
    billingsCount(where: $where)
  }
`;

const BillingsPage = () => {
  const title = `Billings`;

  const { branchFilterQueryString } = useBranchContext();
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;

  const {
    handleChangeStartDate,
    handleChangeEndDate,
    handleClearDateFilters,
    startDate,
    endDate,
    startDateQueryString,
    endDateQueryString,
  } = useDateFilter();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const where = {
    ...(branchFilterQueryString && {
      branch: {
        id: {
          equals: branchFilterQueryString,
        },
      },
    }),
    createdAt: {
      gte: startOfDay(new Date()),
      lte: endOfDay(new Date()),
    },
    ...(startDate &&
      endDate && {
        createdAt: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
        },
      }),

    ...(searchQueryString && {
      OR: [
        {
          tableNumber: {
            equals: searchQueryString,
            mode: "insensitive",
          },
        },
        {
          id: {
            equals: searchQueryString,
            mode: "insensitive",
          },
        },
      ],
    }),
  };
  const { data: { billings } = {}, loading } = useQuery(BILLINGS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
      orderBy: [{ createdAt: "desc" }],
    },
  });

  const variables = {
    ...(startDate && endDate
      ? {
          startDate: format(
            startOfDay(new Date(startDateQueryString)),
            "yyyy-MM-dd"
          ),
          endDate: format(endOfDay(new Date(endDateQueryString)), "yyyy-MM-dd"),
        }
      : {
          startDate: format(startOfDay(new Date()), "yyyy-MM-dd"),
          endDate: format(endOfDay(new Date()), "yyyy-MM-dd"),
        }),
    ...(searchQueryString && {
      searchTerm: searchQueryString,
    }),
    ...(branchFilterQueryString && {
      branchId: branchFilterQueryString,
    }),
  };

  const {
    data: { salesSummaryPerProduct } = {},
    loading: salesSummaryPerProductLoading,
  } = useQuery(SALES_SUMMARY_PER_PRODUCT, {
    fetchPolicy: "network-only",
    variables,
  });

  const {
    data: { salesSummaryTotal } = {},
    loading: salesSummaryTotalLoading,
  } = useQuery(SALES_SUMMARY_TOTAL, {
    fetchPolicy: "network-only",
    variables,
  });

  const {
    data: { salesSummaryPerModeOfPayment } = {},
    loading: salesSummaryPerModeOfPaymentLoading,
  } = useQuery(SALES_SUMMARY_PER_MODE_OF_PAYMENT, {
    fetchPolicy: "network-only",
    variables,
  });

  const columns = [
    {
      header: "Branch",
      accessor: "branch.name",
    },
    {
      header: "Number",
      accessor: "id",
    },
    {
      header: "Table Number",
      accessor: "tableNumber",
    },
    {
      header: "Subotal",
      accessor: "subtotal",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Service Charge",
      accessor: "serviceCharge",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Discount",
      accessor: "discount",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Net Total",
      accessor: "netTotal",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Amount Received",
      accessor: "amountReceived",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Mode of Payment",
      accessor: "modeOfPayment",
      Cell: (children) => children && children.replace(/_/g, " "),
    },
    {
      header: "Items",
      accessor: "billingItems",
      Cell: (children, collapse, setCollapse, index) => {
        const billingItemsColumns = [
          {
            header: "Quantity",
            accessor: "quantity",
            Cell: (children) => children && children.toLocaleString(),
          },
          {
            header: "Product",
            accessor: "product",
          },
          {
            header: "Price",
            accessor: "price",
            Cell: (children) => children && formatMoney(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={billingItemsColumns}
          />
        );
      },
    },
  ];
  if (
    loading ||
    salesSummaryPerProductLoading ||
    salesSummaryTotalLoading ||
    salesSummaryPerModeOfPaymentLoading
  )
    return <Loading />;

  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Typography variant="h3" color="primary" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "flex-end", md: "space-between" }}
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
        >
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleClearDateFilters={handleClearDateFilters}
          />

          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "1fr 3fr",
            },
            gap: 1,
            alignItems: "flex-start",
          }}
        >
          <SalesSummary
            salesSummaryPerProduct={salesSummaryPerProduct}
            salesSummaryPerModeOfPayment={salesSummaryPerModeOfPayment}
            salesSummaryTotal={salesSummaryTotal}
          />

          <Paper className="dataTableContainer">
            <DataTable data={billings} columns={columns} />
          </Paper>
        </Box>

        <Pagination
          query={BILLINGS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </AdminListContainer>
    </>
  );
};

export default BillingsPage;
