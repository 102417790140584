import { grey } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
let theme = createTheme({
  typography: {
    h3: {
      fontWeight: "500",
    },
    h4: {
      fontWeight: "500",
    },
    h5: {
      fontWeight: "500",
    },
  },
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ff0000",
    },
    background: {
      default: grey[100],
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        #root {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }

      `,
    },
  },
});

export default responsiveFontSizes(theme);
