import { gql, useQuery } from "@apollo/client";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { endOfDay, format, startOfDay } from "date-fns";
import queryString from "query-string";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import useDateFilter from "../../../hooks/useDateFilter";
import useQueryString from "../../../hooks/useQueryString";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import iterateObjectValuesNullToBlank from "../../../utils/iterateObjectValuesNullToBlank";
import DataTable from "../../common/DataTable";
import DateFilter from "../../common/DateFilter";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

export const TIMELOG_TYPES = gql`
  query TimelogType {
    __type(name: "TimelogType") {
      enumValues {
        name
      }
    }
  }
`;

export const ATTENDANCES = gql`
  query Attendances(
    $skip: Int
    $take: Int
    $where: AttendanceWhereInput
    $orderBy: [AttendanceOrderByInput!]
  ) {
    attendances(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      branch {
        id
        name
      }
      employee {
        firstName
        lastName
        middleName
      }
      photo
      timelogType
      dateTime
    }
  }
`;

const ATTENDANCES_COUNT = gql`
  query AttendancesCount($where: AttendanceWhereInput) {
    attendancesCount(where: $where)
  }
`;

const AttendancesPage = () => {
  const title = `Attendances`;
  const location = useLocation();

  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;
  const csvLink = useRef(null);
  const [exportData, setExportData] = useState([]);

  const { branchFilterQueryString } = useBranchContext();
  const {
    handleChangeStartDate,
    handleChangeEndDate,
    handleClearDateFilters,
    startDate,
    endDate,
    startDateQueryString,
    endDateQueryString,
  } = useDateFilter();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const { handleSubmitQueryString, initialQueryStringValue } =
    useQueryString("timelogType");
  const {
    data: { __type: { enumValues: timelogTypes } = {} } = {},
    loading: timelogTypesLoading,
  } = useQuery(TIMELOG_TYPES, {
    fetchPolicy: "network-only",
  });

  const where = {
    ...(branchFilterQueryString && {
      branch: {
        id: {
          equals: branchFilterQueryString,
        },
      },
    }),
    ...(startDate &&
      endDate && {
        dateTime: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
        },
      }),
    ...(initialQueryStringValue && {
      timelogType: {
        equals: initialQueryStringValue,
      },
    }),

    ...(searchQueryString && {
      OR: [
        {
          employee: {
            firstName: {
              contains: searchQueryString,
              mode: "insensitive",
            },
          },
        },
        {
          employee: {
            lastName: {
              contains: searchQueryString,
              mode: "insensitive",
            },
          },
        },
      ],
    }),
  };
  const {
    data: { attendances } = {},
    loading,
    refetch,
  } = useQuery(ATTENDANCES, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
      orderBy: [{ dateTime: "desc" }],
    },
  });

  const handleDownload = async () => {
    const { data: { attendances } = {} } = await refetch({
      skip: null,
      take: null,
      where,
    });

    setExportData(
      attendances.map((attendance) => {
        return {
          ...iterateObjectValuesNullToBlank({
            ...attendance,
            dateTime: format(
              new Date(attendance.dateTime),
              "dd MMM yyyy hh:mm:ss a"
            ),
          }),
        };
      })
    );
    csvLink.current.link.click();
  };

  const columns = [
    {
      header: "Branch",
      accessor: "branch.name",
    },
    {
      header: "Last Name",
      accessor: "employee.lastName",
    },
    {
      header: "Firs Name",
      accessor: "employee.firstName",
    },
    {
      header: "Middle Name",
      accessor: "employee.middleName",
    },
    {
      header: "Timelog Type",
      accessor: "timelogType",
      Cell: (children) => children && children.replace(/_/g, " "),
    },
    {
      header: "Date Time",
      accessor: "dateTime",
      Cell: (children) =>
        children && format(new Date(children), "dd MMM yyyy hh:mm:ss a"),
    },
    {
      header: "Photo",
      accessor: "photo",
      Cell: (children) =>
        children && (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_BACKEND_URI}/${children}`}
          >
            Photo
          </Link>
        ),
    },
  ];

  if (loading || timelogTypesLoading) return <Loading />;
  return (
    <>
      <CSVLink
        component="button"
        headers={columns
          .filter(({ accessor }) => accessor !== "id")
          .map((column) => {
            return { label: column.header, key: column.accessor };
          })}
        filename={`${location.pathname}-${
          searchQueryString ? searchQueryString + "-" : ""
        }${format(Date.now(), "yyyy-MM-dd-HH:mm:ss")}.csv`}
        data={exportData}
        ref={csvLink}
      />
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Button
          onClick={handleDownload}
          variant="contained"
          startIcon={<DownloadIcon />}
          sx={{ marginLeft: "auto" }}
        >
          Download
        </Button>
        <Typography variant="h3" color="primary" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
        >
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleClearDateFilters={handleClearDateFilters}
          />

          <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
            <FormControl variant="outlined" size="small">
              <InputLabel id="timelogTypes-label">Timelog Type</InputLabel>
              <Select
                labelId="timelogTypes-label"
                label="Timelog Type"
                id="timelogTypes"
                name="timelogTypes"
                value={initialQueryStringValue ?? ""}
                onChange={(e) => {
                  handleSubmitQueryString("direct")(e);
                }}
                sx={{
                  backgroundColor: (theme) => theme.palette.common.white,
                  minWidth: "300px",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {timelogTypes?.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name.replace(/_/g, " ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Search
              handleChangeSearch={handleChangeSearch}
              handleSubmitSearch={handleSubmitSearch()}
              searchTerm={searchTerm}
            />
          </Stack>
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={attendances} columns={columns} />
        </Paper>
        <Pagination
          query={ATTENDANCES_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </AdminListContainer>
    </>
  );
};

export default AttendancesPage;
