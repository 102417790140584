import { gql, useQuery } from "@apollo/client";
import { format } from "date-fns";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useInputChange } from "../../../hooks/useInputChange";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import EmployeeForm from "./EmployeeForm";

export const EMPLOYEE = gql`
  query Employee($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      id
      lastName
      firstName
      middleName
      mobileNumber
      address
      employmentType
      dateHired
      salaryType
      wage
      emergencyContactPerson
      emergencyContactNumber
      emergencyContactRelationship
    }
  }
`;

const ViewEmployeePage = () => {
  const title = `View Employee`;
  let { id } = useParams();
  const { state, setState, handleChange } = useInputChange({});

  const { data: { employee } = {}, loading } = useQuery(EMPLOYEE, {
    fetchPolicy: "network-only",
    variables: {
      where: { id },
    },
  });

  useEffect(() => {
    if (employee) {
      setState({
        ...employee,
        dateHired: format(new Date(employee.dateHired), "yyyy-MM-dd"),
      });
    }
  }, [employee, setState]);
  if (loading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <EmployeeForm
        title={title}
        state={state}
        setState={setState}
        handleChange={handleChange}
        disabled={true}
      />
    </>
  );
};

export default ViewEmployeePage;
