import { gql, useQuery } from "@apollo/client";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import useQueryString from "../../../hooks/useQueryString";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import DataTable from "../../common/DataTable";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

export const MENU_ITEM_CATEGORIES = gql`
  query MenuItemCategory {
    __type(name: "MenuItemCategory") {
      enumValues {
        name
      }
    }
  }
`;

export const MENU_ITEMS = gql`
  query MenuItems(
    $skip: Int
    $take: Int
    $where: MenuItemWhereInput
    $orderBy: [MenuItemOrderByInput!]
  ) {
    menuItems(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      menuItemCategory
      name
      discontinued
      available
      branch {
        id
        name
      }
    }
  }
`;

const MENU_ITEMS_COUNT = gql`
  query MenuItemsCount($where: MenuItemWhereInput) {
    menuItemsCount(where: $where)
  }
`;

const MenuItemsPage = () => {
  const title = `Menu Items`;
  const { branchFilterQueryString } = useBranchContext();
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;

  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const {
    handleSubmitQueryString: handleSubmitFilter,
    initialQueryStringValue: filterQueryString,
  } = useQueryString("filter");

  const where = {
    ...(filterQueryString && {
      menuItemCategory: {
        equals: filterQueryString,
      },
    }),
    ...(branchFilterQueryString && {
      branch: {
        id: {
          equals: branchFilterQueryString,
        },
      },
    }),
    ...(searchQueryString && {
      OR: [
        {
          name: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
      ],
    }),
  };
  const { data: { menuItems } = {}, loading } = useQuery(MENU_ITEMS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
      orderBy: [
        { discontinued: "asc" },
        { menuItemCategory: "asc" },
        { name: "asc" },
      ],
    },
  });

  const {
    data: { __type: { enumValues: menuItemCategories } = {} } = {},
    loading: menuItemCategoriesLoading,
  } = useQuery(MENU_ITEM_CATEGORIES, {
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      header: "Branch",
      accessor: "branch.name",
    },
    {
      header: "Menu Item Category",
      accessor: "menuItemCategory",
      Cell: (children) => children && children.replace(/_/g, " "),
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "Available?",
      accessor: "available",
      Cell: (children) => (children ? "Yes" : "No"),
    },
    {
      header: "Discontinued?",
      accessor: "discontinued",
      Cell: (children) => (children ? "Yes" : "No"),
    },
  ];
  if (loading || menuItemCategoriesLoading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Typography variant="h3" color="primary" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="flex-end"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
        >
          <FormControl variant="outlined" size="small">
            <InputLabel id="menuItemCategory-label">
              Menu Item Category
            </InputLabel>
            <Select
              labelId="menuItemCategory-label"
              label="Menu Item Category"
              id="menuItemCategory"
              name="menuItemCategory"
              value={filterQueryString ?? ""}
              onChange={(e) => {
                handleSubmitFilter("direct")(e);
              }}
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                minWidth: "300px",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {menuItemCategories?.map(({ name }) => (
                <MenuItem key={name} value={name}>
                  {name.replace(/_/g, " ")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>

        <Paper className="dataTableContainer">
          <DataTable data={menuItems} columns={columns} />
        </Paper>
        <Pagination
          query={MENU_ITEMS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </AdminListContainer>
    </>
  );
};

export default MenuItemsPage;
