import { Divider, Drawer as MuiDrawer, Link } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import logoLightBg from "../../images/logos/logo-light-bg.png";
import DrawerHeader from "../../styles/components/DrawerHeader";
import Nav from "./NavLinks";

const Drawer = ({ drawerOpen, toggleDrawer }) => {
  const navigate = useNavigate();

  return (
    <>
      <MuiDrawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ displayPrint: "none" }}
      >
        <DrawerHeader>
          <Link
            component="button"
            onClick={(e) => {
              toggleDrawer(false)(e);
              navigate("/admin");
            }}
          >
            <Box
              component="img"
              src={logoLightBg}
              alt="logo"
              sx={{
                height: "40px",
                marginLeft: (theme) => theme.spacing(1),
              }}
            />
          </Link>
        </DrawerHeader>
        <Divider />
        <Nav handleDrawerClose={toggleDrawer(false)} />
      </MuiDrawer>
    </>
  );
};

export default Drawer;
