import { gql, useQuery } from "@apollo/client";
import PageviewIcon from "@mui/icons-material/Pageview";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import queryString from "query-string";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import useQueryString from "../../../hooks/useQueryString";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import DataTable from "../../common/DataTable";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

export const EMPLOYMENT_TYPES = gql`
  query EmploymentTypes {
    __type(name: "EmploymentType") {
      enumValues {
        name
      }
    }
  }
`;

export const SALARY_TYPES = gql`
  query SalaryTypes {
    __type(name: "SalaryType") {
      enumValues {
        name
      }
    }
  }
`;

export const EMPLOYEES = gql`
  query Employees($skip: Int, $take: Int, $where: EmployeeWhereInput) {
    employees(skip: $skip, take: $take, where: $where) {
      id
      lastName
      firstName
      middleName
      mobileNumber
      address
      employmentType
      dateHired
      salaryType
      wage
      emergencyContactPerson
      emergencyContactNumber
      emergencyContactRelationship
      branch {
        id
        name
      }
    }
  }
`;

const EMPLOYEES_COUNT = gql`
  query EmployeesCount($where: EmployeeWhereInput) {
    employeesCount(where: $where)
  }
`;

const EmployeesPage = () => {
  const title = `Employees`;
  const { branchFilterQueryString } = useBranchContext();
  const location = useLocation();
  const navigate = useNavigate();
  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;

  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");
  const {
    handleSubmitQueryString: employmentTypeHandleSubmitFilter,
    initialQueryStringValue: employmentTypeFilterQueryString,
  } = useQueryString("filterEmploymentType");
  const {
    handleSubmitQueryString: salaryTypeHandleSubmitFilter,
    initialQueryStringValue: salaryTypeFilterQueryString,
  } = useQueryString("filterSalaryType");
  const where = {
    ...(branchFilterQueryString && {
      branch: {
        id: {
          equals: branchFilterQueryString,
        },
      },
    }),
    ...(employmentTypeFilterQueryString && {
      employmentType: {
        equals: employmentTypeFilterQueryString,
      },
    }),
    ...(salaryTypeFilterQueryString && {
      salaryType: {
        equals: salaryTypeFilterQueryString,
      },
    }),
    ...(searchQueryString && {
      OR: [
        {
          lastName: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
        {
          firstName: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
      ],
    }),
  };
  const { data: { employees } = {}, loading } = useQuery(EMPLOYEES, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
    },
  });

  const {
    data: { __type: { enumValues: employmentTypes } = {} } = {},
    loading: employmentTypesLoading,
  } = useQuery(EMPLOYMENT_TYPES, {
    fetchPolicy: "network-only",
  });

  const {
    data: { __type: { enumValues: salaryTypes } = {} } = {},
    loading: salaryTypesLoading,
  } = useQuery(SALARY_TYPES, {
    fetchPolicy: "network-only",
  });

  const handleView = (id) => {
    navigate(`${location.pathname}/${id}`);
  };

  const columns = [
    {
      header: "Actions",
      accessor: "id",
      Cell: (children) =>
        children && (
          <>
            <Tooltip title="View">
              <IconButton
                aria-label="view"
                color="secondary"
                onClick={() => handleView(children)}
                size="large"
              >
                <PageviewIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
    },

    { header: "Last Name", accessor: "lastName" },
    { header: "First Name", accessor: "firstName" },
    { header: "Middle Name", accessor: "middleName" },
    { header: "Mobile No.", accessor: "mobileNumber" },
    { header: "Branch", accessor: "branch.name" },
  ];
  if (loading || employmentTypesLoading || salaryTypesLoading)
    return <Loading />;
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Typography variant="h3" color="primary" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="flex-end"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
        >
          <FormControl variant="outlined" size="small">
            <InputLabel id="employmentType-label">Employment Type</InputLabel>
            <Select
              labelId="employmentType-label"
              label="Employment Type"
              id="employmentType"
              name="employmentType"
              value={employmentTypeFilterQueryString ?? ""}
              onChange={(e) => {
                employmentTypeHandleSubmitFilter("direct")(e);
              }}
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                minWidth: "250px",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {employmentTypes?.map(({ name }) => (
                <MenuItem key={name} value={name}>
                  {name.replace(/_/g, " ")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small">
            <InputLabel id="salaryType-label">Salary Type</InputLabel>
            <Select
              labelId="salaryType-label"
              label="Salary Type"
              id="salaryType"
              name="salaryType"
              value={salaryTypeFilterQueryString ?? ""}
              onChange={(e) => {
                salaryTypeHandleSubmitFilter("direct")(e);
              }}
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                minWidth: "250px",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {salaryTypes?.map(({ name }) => (
                <MenuItem key={name} value={name}>
                  {name.replace(/_/g, " ")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={employees} columns={columns} />
        </Paper>
        <Pagination
          query={EMPLOYEES_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </AdminListContainer>
    </>
  );
};

export default EmployeesPage;
