import React from "react";
import useQueryString from "../hooks/useQueryString";

const BranchContext = React.createContext();

BranchContext.displayName = "BranchContext";

const BranchProvider = ({ children }) => {
  const {
    handleSubmitQueryString: handleSubmitBranchFilter,
    initialQueryStringValue: branchFilterQueryString,
  } = useQueryString("branchId");

  return (
    <BranchContext.Provider
      value={{
        branchFilterQueryString,
        handleSubmitBranchFilter,
      }}
    >
      {children}
    </BranchContext.Provider>
  );
};

const useBranchContext = () => {
  return React.useContext(BranchContext);
};

export { BranchProvider, useBranchContext };
