import { gql, useQuery } from "@apollo/client";
import { Paper, Stack, Typography } from "@mui/material";
import { endOfDay, format, startOfDay } from "date-fns";
import queryString from "query-string";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useBranchContext } from "../../../contexts/branchContext";
import useDateFilter from "../../../hooks/useDateFilter";
import useQueryString from "../../../hooks/useQueryString";
import AdminListContainer from "../../../styles/components/AdminListContainer";
import formatMoney from "../../../utils/formatMoney";
import DataTable from "../../common/DataTable";
import DataTableAccordion from "../../common/DataTableAccordion";
import DateFilter from "../../common/DateFilter";
import Loading from "../../common/Loading";
import Meta from "../../common/Meta";
import Pagination from "../../common/Pagination";
import Search from "../../common/Search";

export const TRANSFER_SLIPS = gql`
  query TransferSlips(
    $skip: Int
    $take: Int
    $where: TransferSlipWhereInput
    $orderBy: [TransferSlipOrderByInput!]
  ) {
    transferSlips(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      dateBorrowed
      number
      originBranch {
        id
        name
      }
      destination
      dateReturned
      remarks
      total
      transferSlipItems {
        id
        quantity
        item {
          id
          unit
          name
        }
        price
      }
    }
  }
`;

const TRANSFER_SLIPS_COUNT = gql`
  query TransferSlipsCount($where: TransferSlipWhereInput) {
    transferSlipsCount(where: $where)
  }
`;

const TRANSFER_SLIPS_TOTAL = gql`
  query TransferSlipsTotal($where: TransferSlipWhereInput) {
    transferSlipsTotal(where: $where)
  }
`;

const TransferSlipsPage = () => {
  const title = `Transfer Slips`;
  const { branchFilterQueryString } = useBranchContext();

  const paramsObj = queryString.parse(useLocation().search);
  const page = parseInt(paramsObj.page) || 1;
  const perPage = 50;

  const {
    handleChangeStartDate,
    handleChangeEndDate,
    handleClearDateFilters,
    startDate,
    endDate,
    startDateQueryString,
    endDateQueryString,
  } = useDateFilter();
  const {
    handleChangeQueryString: handleChangeSearch,
    handleSubmitQueryString: handleSubmitSearch,
    queryStringValue: searchTerm,
    initialQueryStringValue: searchQueryString,
  } = useQueryString("search");

  const where = {
    ...(startDate &&
      endDate && {
        dateBorrowed: {
          gte: startOfDay(new Date(startDateQueryString)),
          lte: endOfDay(new Date(endDateQueryString)),
        },
      }),
    ...(branchFilterQueryString && {
      branch: {
        id: {
          equals: branchFilterQueryString,
        },
      },
    }),
    ...(searchQueryString && {
      OR: [
        {
          remarks: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
        {
          number: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
        {
          destination: {
            contains: searchQueryString,
            mode: "insensitive",
          },
        },
        {
          transferSlipItems: {
            some: {
              item: {
                name: {
                  contains: searchQueryString,
                  mode: "insensitive",
                },
              },
            },
          },
        },
      ],
    }),
  };
  const { data: { transferSlips } = {}, loading } = useQuery(TRANSFER_SLIPS, {
    fetchPolicy: "network-only",
    variables: {
      skip: page * perPage - perPage,
      take: perPage,
      where,
      orderBy: [{ number: "desc" }],
    },
  });

  const { data: { transferSlipsTotal } = {} } = useQuery(TRANSFER_SLIPS_TOTAL, {
    fetchPolicy: "network-only",
    variables: {
      where,
    },
  });

  const columns = [
    {
      header: "Date Borrwed",
      accessor: "dateBorrowed",
      Cell: (children) => children && format(new Date(children), "dd MMM yyyy"),
    },
    {
      header: "Transfer Slip No.",
      accessor: "number",
    },
    {
      header: "Origin Branch",
      accessor: "originBranch.name",
    },
    {
      header: "Destination",
      accessor: "destination",
    },
    {
      header: "Date Returned",
      accessor: "dateReturned",
      Cell: (children) => children && format(new Date(children), "dd MMM yyyy"),
    },
    {
      header: "Total",
      accessor: "total",
      Cell: (children) => children && formatMoney(children),
    },
    {
      header: "Remarks",
      accessor: "remarks",
    },
    {
      header: "Items",
      accessor: "transferSlipItems",
      Cell: (children, index) => {
        const transferSlipItemsColumns = [
          {
            header: "Quantity",
            accessor: "quantity",
            Cell: (children) => children && children.toLocaleString(),
          },
          {
            header: "Unit",
            accessor: "item.unit",
          },
          {
            header: "Item",
            accessor: "item.name",
          },
          {
            header: "Price",
            accessor: "price",
            Cell: (children) => children && formatMoney(children),
          },
        ];
        return (
          <DataTableAccordion
            title="Items"
            data={children}
            index={index}
            columns={transferSlipItemsColumns}
          />
        );
      },
    },
  ];

  if (loading) return <Loading />;
  return (
    <>
      <Meta title={`${title} | Meatsumo`} />
      <AdminListContainer>
        <Typography variant="h3" color="primary">
          {title}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Total Amount: {transferSlipsTotal && formatMoney(transferSlipsTotal)}
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: null, md: "flex-end" }}
          spacing={1}
        >
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleClearDateFilters={handleClearDateFilters}
          />

          <Search
            handleChangeSearch={handleChangeSearch}
            handleSubmitSearch={handleSubmitSearch()}
            searchTerm={searchTerm}
          />
        </Stack>
        <Paper className="dataTableContainer">
          <DataTable data={transferSlips} columns={columns} />
        </Paper>
        <Pagination
          query={TRANSFER_SLIPS_COUNT}
          variables={{
            where,
          }}
          perPage={perPage}
          page={Number(page)}
        />
      </AdminListContainer>
    </>
  );
};

export default TransferSlipsPage;
