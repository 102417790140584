import MuiDialog from "@mui/material/Dialog";
import React from "react";
import { useDialogContext } from "../../contexts/dialogContext";

const Dialog = () => {
  const { dialogOpen, handleDialogClose, dialogComponent } = useDialogContext();
  return (
    <>
      <MuiDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-describedby="dialog"
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "300px",
          },
        }}
      >
        {dialogComponent}
      </MuiDialog>
    </>
  );
};

export default Dialog;
