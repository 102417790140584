import { Container, Paper } from "@mui/material";
import React from "react";

const FormContainer = ({ children, maxWidth = "lg" }) => {
  return (
    <>
      <Container
        maxWidth={maxWidth}
        sx={{
          margin: "auto",
        }}
      >
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          {children}
        </Paper>
      </Container>
    </>
  );
};

export default FormContainer;
