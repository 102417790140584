import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import FormContainer from "../../common/FormContainer";
import Loading from "../../common/Loading";
import { EMPLOYMENT_TYPES, SALARY_TYPES } from "./EmployeesPage";

const EmployeeForm = ({
  title = "",
  state = {},
  branch = "",
  handleChange = () => {},
  handleSubmit = () => {},
  mutationLoading = false,
  disabled,
}) => {
  const {
    data: { __type: { enumValues: employmentTypes } = {} } = {},
    loading: employmentTypesLoading,
  } = useQuery(EMPLOYMENT_TYPES, {
    fetchPolicy: "network-only",
  });

  const {
    data: { __type: { enumValues: salaryTypes } = {} } = {},
    loading: salaryTypesLoading,
  } = useQuery(SALARY_TYPES, {
    fetchPolicy: "network-only",
  });
  if (employmentTypesLoading || salaryTypesLoading) return <Loading />;

  return (
    <FormContainer maxWidth="md">
      <Typography
        component="h1"
        variant="h4"
        sx={{ textTransform: "uppercase", fontWeight: "bold" }}
        gutterBottom={!branch}
      >
        {title}
      </Typography>
      {branch && (
        <Typography variant="subtitle2" color="secondary" sx={{ mb: 2 }}>
          For {branch.name} branch
        </Typography>
      )}
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              disabled={disabled}
              required
              fullWidth
              id="lastName"
              label="Last name"
              name="lastName"
              value={state.lastName ?? ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={disabled}
              required
              fullWidth
              name="firstName"
              label="First name"
              type="firstName"
              id="firstName"
              value={state.firstName ?? ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={disabled}
              required
              fullWidth
              name="middleName"
              label="Middle name"
              type="middleName"
              id="middleName"
              value={state.middleName ?? ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={disabled}
              required
              fullWidth
              name="mobileNumber"
              label="Mobile"
              type="mobileNumber"
              id="mobileNumber"
              value={state.mobileNumber ?? ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={disabled}
              required
              fullWidth
              name="address"
              label="Address"
              type="address"
              id="address"
              value={state.address ?? ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth required disabled={disabled}>
              <InputLabel id="employmentType-label">Employment type</InputLabel>
              <Select
                labelId="employmentType-label"
                label="Employment type*"
                id="employmentType"
                name="employmentType"
                value={state.employmentType ?? ""}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {employmentTypes.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name.replace(/_/g, " ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              disabled={disabled}
              required
              id="dateHired"
              label="Date hired"
              name="dateHired"
              value={state.dateHired ?? ""}
              onChange={handleChange}
              fullWidth
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth required disabled={disabled}>
              <InputLabel id="salaryType-label">Salary type</InputLabel>
              <Select
                labelId="salaryType-label"
                label="Salary type*"
                id="salaryType"
                name="salaryType"
                value={state.salaryType ?? ""}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {salaryTypes.map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name.replace(/_/g, " ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              disabled={disabled}
              required
              fullWidth
              id="wage"
              label="Wage"
              name="wage"
              value={state.wage ?? ""}
              onChange={handleChange}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₱</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={disabled}
              required
              fullWidth
              id="emergencyContactPerson"
              label="Emergency contact person"
              name="emergencyContactPerson"
              value={state.emergencyContactPerson ?? ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={disabled}
              required
              fullWidth
              id="emergencyContactNumber"
              label="Emergency contact number"
              name="emergencyContactNumber"
              value={state.emergencyContactNumber ?? ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={disabled}
              required
              fullWidth
              id="emergencyContactRelationship"
              label="Relationship"
              name="emergencyContactRelationship"
              value={state.emergencyContactRelationship ?? ""}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        {!disabled && (
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            disabled={mutationLoading}
          >
            Submit
          </Button>
        )}
      </Box>
    </FormContainer>
  );
};

export default EmployeeForm;
