import { styled } from "@mui/material";

const DrawerHeader = styled("div")(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media print": { display: "none" },
}));

export default DrawerHeader;
